import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";

import DownloadIcon from "@mui/icons-material/Download";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { handleDate } from "common/utils/handleDate";
import { HiddenLink } from "components/UI/HiddenLink";
import { MUIIconButton } from "components/UI/MUIIconButton";
import { Pagination } from "components/UI/Pagination";
import { fetchAndDownloadFile } from "pages/media-plan/helpers";
import {
  PlanUpload,
  SendUltimateUploadParams,
} from "services/store/reducers/media/types";

import commonStyles from "../common-styles.module.sass";
import styles from "./styles.module.sass";
import { columns, Row } from "./types";

interface Props {
  data: PlanUpload[];
  choosePlan?: Dispatch<SetStateAction<SendUltimateUploadParams | null>>;
  navigable?: boolean;
}

export default function PlanUploadsTable({
  data,
  choosePlan,
  navigable = false,
}: Props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);
  const [selected, setSelected] = useState<number>(0);
  const navigate = useNavigate();

  const downloadRef = useRef<HTMLAnchorElement>(null);

  const rows: Row[] = data.map((row: PlanUpload) => {
    return {
      planId: row.id,
      date: handleDate(row.uploaded_at, true, true),
      author: row.uploader_name,
    };
  });

  const isSelected = (id: number): boolean => selected === id;

  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage);
  };

  const handleGetExcel = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
  ): void => {
    event.stopPropagation();
    fetchAndDownloadFile(
      `/recruitment-plan/get-recruitment-plan/${id}`,
      downloadRef,
    );
  };

  const handleNavigate = (plan: number): void => {
    navigate(`/mediaplan?plan=selection&id=${plan}&history=selection`);
  };

  const handleChoose = (id: number): void => {
    if (selected === id) return;
    setSelected(id);
    if (choosePlan)
      choosePlan({
        client_id: data[id].client_id,
        uploader_name: data[id].uploader_name,
        plan_id: data[id].id,
      });
  };

  useEffect(() => {
    if (choosePlan)
      choosePlan({
        client_id: data[0].client_id,
        uploader_name: data[0].uploader_name,
        plan_id: data[0].id,
      });
  }, [data, choosePlan]);

  return (
    <>
      <Paper className={commonStyles.table}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {!navigable && <TableCell padding="checkbox"></TableCell>}
                {columns.map((column, id) => (
                  <TableCell className={commonStyles.tableHead} key={id}>
                    {column.label}
                  </TableCell>
                ))}
                {navigable && <TableCell padding="checkbox"></TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, id): JSX.Element => {
                  const isItemSelected: boolean = isSelected(id);
                  return (
                    <TableRow
                      hover
                      onClick={(): void =>
                        navigable
                          ? handleNavigate(row.planId)
                          : handleChoose(id)
                      }
                      aria-checked={isItemSelected}
                      role="checkbox"
                      tabIndex={-1}
                      key={id}
                      className={commonStyles.hoverable}
                    >
                      {!navigable && (
                        <TableCell padding="checkbox">
                          <Checkbox checked={isItemSelected} color="primary" />
                        </TableCell>
                      )}
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            className={commonStyles.dataCell}
                            key={column.id}
                          >
                            {value}
                          </TableCell>
                        );
                      })}
                      {navigable && (
                        <TableCell padding="checkbox">
                          <MUIIconButton
                            className={styles.downloadButton}
                            icon={
                              <DownloadIcon
                                className={styles.download}
                                color="success"
                              />
                            }
                            onClick={(
                              event: React.MouseEvent<
                                HTMLButtonElement,
                                MouseEvent
                              >,
                            ) => handleGetExcel(event, row.planId)}
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Pagination
        total={rows.length}
        limit={rowsPerPage}
        currentPage={page}
        onPageChange={handleChangePage}
        className={styles.pagination}
      />
      <HiddenLink ref={downloadRef} />
    </>
  );
}
