import { AxiosResponse } from "axios";

import { apiRequest } from "../apiRequest";

export const uploadFile = <T>(
  endpoint: string,
  formData: FormData,
): Promise<AxiosResponse<T>> => {
  return apiRequest<T>("API", "post", endpoint, formData);
};

export const downloadFile = <T>(
  endpoint: string,
  params?:
    | string
    | string[][]
    | Record<string, string>
    | URLSearchParams
    | undefined,
) => {
  let url: string = endpoint;
  if (params && endpoint.includes("?")) {
    url += `&${new URLSearchParams(params)}`;
  } else if (params) url += `?${new URLSearchParams(params)}`;
  return apiRequest<T>("API", "get", url);
};
