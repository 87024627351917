import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import AddCircleIcon from "@mui/icons-material/AddCircle";

import { SettingsTable } from "components/SettingsTable";
import { Rates } from "components/SettingsTable/types";
import { H1, H2 } from "components/typography";
import { Button } from "components/UI/Button";
import { setGlobalParams } from "services/store/reducers/globalParams";
import {
  fetchClientSources,
  postSettingsData,
  updateSettingsData,
} from "services/store/reducers/settings";
import { SettingSource } from "services/store/reducers/settings/types";
import { useAppDispatch, useAppSelector } from "services/store/store";

import { catchCollisions } from "./helpers";
import styles from "./styles.module.sass";
import { isMessageResponse, isSourceData } from "./typeguards";

interface RateDetails {
  access_price: number;
  additional: string;
  calculation_type: number;
  client_id: number;
  code: string;
  id: number;
  name: string;
  price: number;
  search_type: string;
  source: string;
  source_id: number;
  tariff_type: string;
  uploader_name: string;
  use: number;
}

const SettingsPage: React.FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { uploaderName: uploader_name, clientId: client_id } = useAppSelector(
    (state) => state.globalParams,
  );
  const [selected, setSelected] = useState<readonly number[]>([]);
  const [newRatesIds, setNewRatesIds] = useState<number[]>([]);
  const { source, sourceUpdate } = useAppSelector((state) => state.settings);
  const { data } = source;
  const [rates, setRates] = useState<Rates>(isSourceData(data) ? data : []);
  const [collisions, setCollisions] = useState<number[]>([]);
  const [shouldRefresh, setShouldRefresh] = useState<boolean>(false);

  const { uploaderName, clientId } = useMemo(() => {
    const urlParams = new URLSearchParams(location.search);
    return {
      uploaderName: urlParams.get("uploader_name"),
      clientId: parseInt(urlParams.get("client_id") ?? "0", 10),
    };
  }, [location.search]);

  const addCollision = (id: number) => {
    setCollisions((prevCollisions) => [...prevCollisions, id]);
  };

  const handleSaveClick = (): void => {
    if (sourceUpdate.loading) return;
    if (isMessageResponse(rates)) return;

    const rateKeys = Object.keys(rates).map(Number);
    const filteredKeys = rateKeys.filter((key) => selected.includes(key));
    const filteredRates = filteredKeys.reduce(
      (acc: RateDetails, key: number) => {
        const { source, search_type, additional } = rates[key];

        let code = "";

        if (search_type === "Входящий") {
          if (source === "Avito") {
            code =
              additional?.toLowerCase() === "без продвижений" ? "AV" : "AVD";
          } else if (source === "hh.ru") {
            switch (additional) {
              case "Стандарт":
                code = "HV";
                break;
              case "Стандарт +":
                code = "HVC";
                break;
              case "Премиум":
                code = "HVD";
                break;
              default:
                code = "";
            }
          } else if (source === "rabota") {
            code = additional?.toUpperCase() ?? "RA";
          } else if (source === "zarplata") {
            code = additional?.toUpperCase() ?? "ZA";
          } else if (source === "superjob") {
            code = additional?.toUpperCase() ?? "SA";
          }
        }

        const composedName = [source, search_type, additional].join(" ");
        const calculationType = source === "Avito" ? 2 : 3;

        const rateDetail = {
          ...rates[key],
          access_price: 0,
          client_id,
          uploader_name: uploader_name,
          name: composedName,
          calculation_type: calculationType,
          code,
        };

        acc[key] = rateDetail;
        return acc;
      },
      {} as RateDetails,
    );

    setCollisions([]);
    Object.values(filteredRates).forEach((rateDetails) => {
      if (newRatesIds.includes(rateDetails.id)) {
        dispatch(postSettingsData({ settings: rateDetails })).then((data) => {
          if (isMessageResponse(data.payload)) {
            const id: number = catchCollisions(
              data.meta.arg.settings,
              data.payload,
            );
            if (id !== -1) addCollision(id);
            else {
              setNewRatesIds((ratesIds) => {
                const index: number = ratesIds.indexOf(rateDetails.id);
                if (index !== -1) ratesIds.splice(index, 1);
                return ratesIds;
              });
              setShouldRefresh(true);
            }
          }
        });
      } else {
        dispatch(updateSettingsData({ settings: rateDetails })).then((data) => {
          if (isMessageResponse(data.payload)) {
            const id: number = catchCollisions(
              data.meta.arg.settings,
              data.payload,
            );
            if (id !== -1) addCollision(id);
          }
        });
      }
    });
  };

  const handleAddRow = () => {
    const nextId: number = Math.max(...Object.keys(rates).map(Number)) + 1 || 1;
    setSelected((prevSelected) => [...prevSelected, nextId]);

    const additionalRate: SettingSource = {
      id: nextId,
      uploader_name: uploaderName || "",
      source_id: "",
      search_type: "",
      source: "",
      additional: "",
      code: "",
      calculation_type: "",
      tariff_type: "",
      access_price: 0,
      price: 0,
      name: "",
      use: 0,
      client_id: clientId,
    };

    setRates(
      isMessageResponse(rates)
        ? { [nextId]: additionalRate }
        : (prevRates) => ({ ...prevRates, [nextId]: additionalRate }),
    );

    setNewRatesIds((newRates) => [...newRates, nextId]);
  };

  useEffect(() => {
    if (client_id !== 0) dispatch(fetchClientSources({ client_id }));
  }, [dispatch, client_id]);

  useEffect(() => {
    if (isSourceData(data) && Object.keys(data).length > 0) {
      setRates(data);
      const newSelected = Object.keys(data).map((key) => parseInt(key));
      setSelected(newSelected);
    }
  }, [data]);

  useEffect(() => {
    if (clientId && uploaderName) {
      dispatch(setGlobalParams({ uploaderName, clientId }));
    }
  }, [dispatch, uploaderName, clientId]);

  useEffect(() => {
    if (shouldRefresh) {
      dispatch(fetchClientSources({ client_id }));
      setShouldRefresh(false);
    }
  }, [shouldRefresh, dispatch, client_id]);

  return (
    <>
      <section>
        <H1 color="#0C3953">Выбрать доступные типы размещения</H1>
      </section>
      <section className={styles.tableData}>
        {isMessageResponse(data) && isMessageResponse(rates) ? (
          <H2 className={styles.centered}>{data.response.error}</H2>
        ) : data ? (
          <SettingsTable
            rates={rates}
            setRates={setRates}
            selected={selected}
            setSelected={setSelected}
            errors={collisions}
          />
        ) : (
          ""
        )}
        {collisions.length ? (
          <p className={`${styles.note} ${styles.error} ${styles.centered}`}>
            Код источника должен быть уникален
          </p>
        ) : (
          ""
        )}
        <AddCircleIcon
          htmlColor="#217EC6"
          onClick={handleAddRow}
          className={`${styles.centered} ${styles.hoverable}`}
        />
        <p className={styles.note}>
          Если тарифы не заполнены, используются системные тарифы по умолчанию
        </p>
        <Button
          variant="primary"
          loading={sourceUpdate.loading}
          disabled={sourceUpdate.loading}
          onClick={handleSaveClick}
        >
          Сохранить
        </Button>
      </section>
    </>
  );
};

export default SettingsPage;
