import { NavLink } from "react-router-dom";

import styles from "./styles.module.sass";

const Logo: React.FC = (): JSX.Element => {
  return (
    <div className={styles.logo}>
      <NavLink to="/">
        <img src="/HR-Rocket-logo.png" alt="logo" />
      </NavLink>
    </div>
  );
};

export default Logo;
