import { ThemeProvider } from "@emotion/react";
import { BrowserRouter } from "react-router-dom";

import getTheme from "./theme";
import AppRouter from "router/app-router";
import { Provider } from "react-redux";
import store from "services/store/store";

const App: React.FC = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <ThemeProvider theme={getTheme()}>
          <AppRouter />
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  );
};

export default App;
