import axios, { AxiosResponse } from "axios";
import { API_URL, initState } from "common/const";

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getId } from "./helpers";
import {
  DeleteHvAvDataParams,
  DeleteHvAvDataResponse,
  FetchHvAvDataParams,
  HvAvData,
  StateType,
} from "./types";

const initialState: StateType = {
  hvAvData: initState,
  hvAvDelete: initState,
};

//TODO: потом протипизируем ответ
export const fetchHvAvData = createAsyncThunk(
  "wsData/fetchHvAvData",
  async (params: FetchHvAvDataParams, { rejectWithValue }) => {
    try {
      const URLparams: URLSearchParams = new URLSearchParams({
        ...params,
      });
      const response: AxiosResponse<HvAvData[]> = await axios.get(
        `${API_URL}/hr-data/get-hr?${URLparams.toString()}&limit=500`,
      );
      const data: HvAvData[] = response.data;
      return data;
    } catch (error) {
      return axios.isAxiosError(error) && error.response
        ? rejectWithValue(error.response.data)
        : rejectWithValue(error.message);
    }
  },
);

export const deleteHvAvData = createAsyncThunk<any, DeleteHvAvDataParams>(
  "wsData/deleteHvAvData",
  async ({ source_type, source_id }, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<DeleteHvAvDataResponse> = await axios({
        method: "delete",
        url: `${API_URL}/hr-data/delete-hr/${source_type}/${source_id}`,
      });
      return response.data;
    } catch (error) {
      return axios.isAxiosError(error) && error.response
        ? rejectWithValue(error.response.data)
        : rejectWithValue(error.message);
    }
  },
);

const wsDataSlice = createSlice({
  name: "wsData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHvAvData.pending, (state) => {
        state.hvAvData.loading = true;
        state.hvAvData.error = null;
      })
      .addCase(
        fetchHvAvData.fulfilled,
        (state, action: PayloadAction<HvAvData[]>) => {
          state.hvAvData.loading = false;
          state.hvAvData.data = action.payload;
        },
      )
      .addCase(fetchHvAvData.rejected, (state, action) => {
        state.hvAvData.loading = false;
        if (typeof action.payload === "string")
          state.hvAvData.error = action.payload;
      });

    builder
      .addCase(deleteHvAvData.pending, (state) => {
        state.hvAvDelete.loading = true;
        state.hvAvDelete.error = null;
      })
      .addCase(
        deleteHvAvData.fulfilled,
        (state, action: PayloadAction<DeleteHvAvDataResponse>) => {
          state.hvAvDelete.loading = false;
          state.hvAvDelete.data = action.payload;
          const message: string | undefined = action.payload.response.message;
          if (message && state.hvAvData.data) {
            state.hvAvData.data = state.hvAvData.data.filter(
              (entry: HvAvData) => entry.id !== getId(message),
            );
          }
        },
      )
      .addCase(deleteHvAvData.rejected, (state, action) => {
        state.hvAvDelete.loading = false;
        if (typeof action.payload === "string")
          state.hvAvDelete.error = action.payload;
      });
  },
});

export const wsDataReducer = wsDataSlice.reducer;
