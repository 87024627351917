import { Link } from "react-router-dom";

import styles from "./styles.module.sass";

interface Props {
  title: string;
  page?: { name: string; to: string };
}

const ToDoItem: React.FC<Props> = ({ title, page }): JSX.Element => (
  <>
    <p className={styles.toDoItem}>
      {title}
      {page && (
        <span style={{ marginLeft: "20px" }}>
          <Link to={page.to}>{page.name}</Link>
        </span>
      )}
    </p>
  </>
);

export default ToDoItem;
