import React from "react";

import styles from "./styles.module.sass";

interface ErrorMessagesProps extends React.HTMLAttributes<HTMLUListElement> {
  errors: string[];
}

const ErrorMessages: React.FC<ErrorMessagesProps> = ({
  errors,
  className,
  ...props
}) => (
  <ul className={`${styles.errors} ${className}`} {...props}>
    {errors.map((error) => (
      <li key={error}>{error}</li>
    ))}
  </ul>
);

export default ErrorMessages;
