import { useEffect, useState, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import { SIDEBAR_ROUTES, TAB_ROUTES } from "../../common/const";
import { getLocationIndex } from "common/utils/getLocationIndex";

import styles from "./styles.module.sass";
import Logo from "components/UI/Logo";


const SideBar: React.FC = (): JSX.Element => {
  const sidebarLocation: string = `/${useLocation().pathname.split("/")[1]}`;
  let locationIndex: number = getLocationIndex(sidebarLocation, SIDEBAR_ROUTES);
  const [selectedIndex, setSelectedIndex] = useState<number>(locationIndex);

  const locationIndexRef = useRef<number>(-1);

  useEffect(() => {
    locationIndexRef.current = getLocationIndex(sidebarLocation, SIDEBAR_ROUTES);
    if (locationIndexRef.current === -1) {
      const tabIndex = getLocationIndex(sidebarLocation, TAB_ROUTES);
      if (tabIndex !== -1) setSelectedIndex(0);
      else setSelectedIndex(-1);
    }
  }, [sidebarLocation]);

  useEffect(() => {
    setSelectedIndex(locationIndex);
  }, [locationIndex]);

  const handleListItemClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
  };

  return (
    <List
      component="nav"
      className={styles.navigation}
      aria-label="secondary mailbox folders"
    >
      <Logo />
      {SIDEBAR_ROUTES.map((route, index) => (
        <ListItem disablePadding key={index}>
          <ListItemButton
            className={
              selectedIndex === index
                ? `${styles.selected} ${styles.listItem}`
                : styles.listItem
            }
            selected={selectedIndex === index}
            onClick={(event) => handleListItemClick(event, index)}
            component={NavLink}
            to={route.value}
          >
            <ListItemText disableTypography primary={route.name} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

export default SideBar;
