// TODO: change font to Montserrat
import React from "react";
import ModalMUI, { ModalProps as MuiModalProps } from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { SxProps, Theme } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

interface ModalProps extends MuiModalProps {}

const defaultStyles: SxProps<Theme> = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  width: 700,
  fontFamily: "Montserrat, Arial, 'Helvetica Neue', Helvetica, sans-serif",
};

export const Modal: React.FC<ModalProps> = ({ ...props }) => {
  const { children, sx, onClose } = props;

  const handleCloseClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onClose) {
      // Технически, мы не можем изменить тип reason, поэтому используем 'backdropClick' как заглушку.
      onClose(event, "backdropClick");
    }
  };
  return (
    <ModalMUI {...props}>
      <Box sx={{ ...defaultStyles, ...sx }}>
        <IconButton
          aria-label="close"
          onClick={handleCloseClick}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        {children}
      </Box>
    </ModalMUI>
  );
};
