import { useEffect, useRef, useState } from "react";

import { uploadFile } from "api/Rocket";
import { AxiosResponse } from "axios";
import {
  EXCEL_ACCEPT_FORMAT,
  HV_AV_DATA_COLUMNS,
  HV_AV_FILTERS,
  SOURCE_OPTIONS,
  SUCCESS_FILE_UPLOAD,
} from "common/const";
import { useModal } from "common/hooks/useModal";
import useNotify from "common/hooks/useNotify";
import { useUploadHandler } from "common/hooks/useUploadHandler";
import { UploadMessage, UploadResponse } from "common/interfaces";
import { addOptionalParams } from "common/utils/addOptionalParams";
import hasFullAccess from "common/utils/checkRole";
import { handleDate } from "common/utils/handleDate";
import { Filters, FilterValues } from "components/Filters";
import HvAvDataTable from "components/tables/HvAvDataTable";
import { H1, H2 } from "components/typography";
import { Button } from "components/UI/Button";
import DatePicker from "components/UI/DatePicker";
import { Loader } from "components/UI/Loader";
import { Message } from "components/UI/Message";
import { Modal } from "components/UI/Modal";
import BasicSelect from "components/UI/Select";
import Upload from "components/UI/Upload";
import { isSourceType } from "pages/settings/typeguards";
import { deleteHvAvData, fetchHvAvData } from "services/store/reducers/ws-data";
import {
  FetchHvAvDataParams,
  SourceType,
} from "services/store/reducers/ws-data/types";
import { useAppDispatch, useAppSelector } from "services/store/store";
import useSessionStorageState from "use-session-storage-state";

import commonStyles from "../common-styles.module.sass";
import styles from "./styles.module.sass";

const WSDataPage: React.FC = (): JSX.Element => {
  const { uploaderName, clientId, role } = useAppSelector(
    (state) => state.globalParams,
  );
  const { hvAvData } = useAppSelector((state) => state.wsdata);
  const dispatch = useAppDispatch();

  const [select, setSelect] = useState("");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [isSaving, setSaving] = useState<boolean>(false);
  const [sourceType] = useState<SourceType>("hv");
  const [deleteNumber, setDeleteNumber] = useState<number | null>(null);
  const [errors, setErrors] = useState<string[]>();

  const [uploadMessage, setUploadMessage] =
    useSessionStorageState<UploadMessage | null>("upload-message-wsdata");
  const [uploadMessageLink, setUploadMessageLink] = useSessionStorageState<
    string | null
  >("upload-message-wsdata-link");
  const { fileName, file, handleUpload } = useUploadHandler();
  const { ToastContainer, notifyUploading } = useNotify();
  const { isOpen, openModal, closeModal } = useModal();
  const uploadRef = useRef<HTMLInputElement>(null);

  const handleSelect = (newValue: string): void => {
    setSelect(newValue);
  };
  const handleStartDateSelect = (newValue: Date | null): void => {
    setStartDate(newValue);
  };
  const handleEndDateSelect = (newValue: Date | null): void => {
    setEndDate(newValue);
  };
  const handleButtonClick = (): void => {
    if (uploadRef.current) uploadRef.current.click();
  };
  const handleConfirmation = (id: number): void => {
    setDeleteNumber(id);
    openModal();
  };
  const handleDelete = (): void => {
    closeModal();
    if (deleteNumber !== null)
      dispatch(
        deleteHvAvData({
          source_id: deleteNumber.toString(),
          source_type: sourceType,
        }),
      );
  };

  const resetStates = (): void => {
    setSaving(true);
    setUploadMessage(null);
    setUploadMessageLink(null);
  };

  const handleSaveClick = (): void => {
    if (!isSaving && file && select && startDate && endDate) {
      resetStates();
      const formData: FormData = new FormData();
      const params: URLSearchParams = new URLSearchParams({
        client_id: clientId.toString(),
        uploader_name: uploaderName,
        date_from: handleDate(startDate),
        date_to: handleDate(endDate),
      });
      const endpoint: string = `/hr-data/upload-${select}?${params.toString()}`;

      formData.append("file", file);

      uploadFile<UploadResponse>(endpoint, formData)
        .then((response: AxiosResponse<UploadResponse>) => {
          if (
            "error" in response.data.response &&
            response.data.response.error
          ) {
            notifyUploading(fileName, "error");
            setUploadMessage({
              message: `${fileName} : ${response.data.response.error}`,
              success: false,
            });
            setUploadMessageLink(response.data.response.fileLink);
          } else {
            notifyUploading(fileName, "success");
            setUploadMessage({
              message: `${fileName} : ${SUCCESS_FILE_UPLOAD}`,
              success: true,
            });
          }
        })
        .catch((error: Error) => {
          setUploadMessage({
            message: `${fileName} : ${error.message}`,
            success: false,
          });
        })
        .finally(() => {
          setSaving(false);
        });
    }
  };

  const handleUpdateFilters = (filterValues: FilterValues): void => {
    if (!isSourceType(filterValues.source_type)) {
      setErrors([HV_AV_FILTERS.errorsPhrases.source_type]);
      return;
    }

    const sendParams: FetchHvAvDataParams = {
      client_id: clientId.toString(),
      source_type: filterValues.source_type,
      date_from: filterValues.date_from.toString(),
      date_to: filterValues.date_to.toString(),
    };
    const optionalFields: FilterValues = {
      uploaded_from: filterValues.uploaded_from,
      uploaded_to: filterValues.uploaded_to,
      profile: filterValues.profile,
      region: filterValues.region,
    };
    addOptionalParams(sendParams, optionalFields);

    dispatch(fetchHvAvData(sendParams));
  };

  useEffect(() => {
    const sourceType = HV_AV_FILTERS.defaults.source_type;
    if (isSourceType(sourceType)) {
      dispatch(
        fetchHvAvData({
          client_id: clientId.toString(),
          source_type: sourceType,
          date_from: HV_AV_FILTERS.defaults.date_from.toString(),
          date_to: HV_AV_FILTERS.defaults.date_to.toString(),
        }),
      );
    }
  }, [dispatch, clientId, sourceType]);

  return (
    <>
      <section className={commonStyles.heading}>
        <H1 color="#0C3953">Данные работных сайтов</H1>
        <Filters
          onUpdateFilters={handleUpdateFilters}
          configuration={HV_AV_FILTERS}
          errors={errors}
        />
      </section>
      {hasFullAccess(role) && (
        <section className={styles.upload}>
          <BasicSelect
            label="Работный сайт"
            options={SOURCE_OPTIONS}
            variant="standard"
            select={select}
            setSelect={handleSelect}
          />
          <div className={styles.datePickers}>
            <DatePicker value={startDate} setValue={handleStartDateSelect} />
            <span className={styles.divider}>–</span>
            <DatePicker value={endDate} setValue={handleEndDateSelect} />
          </div>
          <div className={styles.confirmation}>
            <Upload
              forwardRef={uploadRef}
              id="upload"
              onUpload={handleUpload}
              accept={EXCEL_ACCEPT_FORMAT}
              label={fileName}
            >
              <Button
                variant="upload"
                loading={isSaving}
                disabled={isSaving}
                onClick={handleButtonClick}
              >
                Выберите файл
              </Button>
            </Upload>
            <Button
              variant="primary"
              onClick={handleSaveClick}
              style={{ placeSelf: "end" }}
              loading={isSaving}
              disabled={isSaving}
            >
              Сохранить
            </Button>
          </div>
          <ToastContainer />
          {uploadMessage && (
            <Message
              text={uploadMessage.message}
              success={uploadMessage.success}
              link={uploadMessageLink}
            />
          )}
        </section>
      )}
      <section>
        {hvAvData.loading ? (
          <Loader />
        ) : (
          <HvAvDataTable
            data={hvAvData.data}
            sourceType={sourceType}
            onDeletion={handleConfirmation}
            columns={HV_AV_DATA_COLUMNS}
          />
        )}
        <Modal open={isOpen} onClose={closeModal}>
          <div>
            <H2>Вы действительно хотите удалить запись №{deleteNumber}?</H2>
            <div className={styles.affirmation}>
              <Button variant="delete" onClick={handleDelete}>
                Да
              </Button>
              <Button variant="download" onClick={closeModal}>
                Нет
              </Button>
            </div>
          </div>
        </Modal>
      </section>
    </>
  );
};

export default WSDataPage;
