import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import MUISelect, {
  SelectChangeEvent,
  SelectProps,
} from "@mui/material/Select";
import { SxProps } from "@mui/system";

import styles from "./styles.module.sass";

export type optionType = {
  name: string;
  value: string;
};
interface AdditionalSelectOptions {
  options: optionType[];
  label?: string;
  select: string;
  setSelect: (select: string) => void;
  sx?: SxProps;
  isFullWidth?: boolean;
}

type SelectOptions = SelectProps<string> & AdditionalSelectOptions;

const BasicSelect: React.FC<SelectOptions> = ({
  options,
  label,
  select,
  setSelect,
  isFullWidth = false,
  sx,
  className,
}): JSX.Element => {
  const handleChange = (event: SelectChangeEvent) => {
    setSelect(event.target.value);
  };

  return (
    <FormControl className={isFullWidth ? "" : styles.select}>
      {label && <InputLabel>{label}</InputLabel>}
      <MUISelect
        sx={{ ...sx, borderRadius: "8px" }}
        value={select}
        label={label}
        onChange={handleChange}
        className={className}
      >
        {options.map((option, key) => (
          <MenuItem value={option.value} key={key}>
            {option.name}
          </MenuItem>
        ))}
      </MUISelect>
    </FormControl>
  );
};

export default BasicSelect;
