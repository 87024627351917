import { FilterValues } from "components/Filters";

export function addOptionalParams<T extends object>(
  params: T,
  optionalFields: FilterValues,
): void {
  Object.entries(optionalFields).forEach(([key, value]) => {
    if (value !== undefined && value !== null && value !== "")
      params[key] = value.toString().trim();
  });
}
