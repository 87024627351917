import React from "react";

import MUIInput, { InputProps as MUIInputProps } from "@mui/material/Input";

import classNames from "classnames";

import "./styles.scss";

export interface InputProps extends MUIInputProps {}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ style, className, disabled, required, ...props }, ref) => {
    const inputClassNames = classNames(
      "ui-input",
      {
        "ui-input--disabled": disabled,
        "ui-input--required": required,
      },
      className,
    );
    return (
      <MUIInput
        inputRef={ref}
        className={inputClassNames}
        style={{ ...style }}
        disabled={disabled}
        required={required}
        {...props}
      />
    );
  },
);
