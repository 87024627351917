import { Grid } from "react-loader-spinner";
import styles from "./styles.module.sass";

export const Loader: React.FC = (): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <Grid
        visible={true}
        height="40"
        width="40"
        color="#217EC6"
        ariaLabel="grid-loading"
        radius="12.5"
        wrapperStyle={{}}
        wrapperClass="grid-wrapper"
      />
    </div>
  );
};
