import styled from '@emotion/styled';

import {TTextProps} from './types';

export default styled.div<TTextProps>`
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
    color: ${({color, theme}) => color ?? theme.colors.text.light};
`;
