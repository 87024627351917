import React from "react";

import classNames from "classnames";

import styles from "./styles.module.sass";

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  icon: JSX.Element;
}

export const MUIIconButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ className, icon, ...props }, ref) => {
    const combinedClassName = classNames(styles.button, className);
    return (
      <button ref={ref} className={combinedClassName} {...props}>
        {icon}
      </button>
    );
  }
);
