type ErLoad = {
  loading: boolean;
  error: string | null;
};

export const SOURCE_TYPE = [
  "hv",
  "ha",
  "av",
  "aa",
  "rv",
  "zv",
  "za",
  "sv",
] as const;
export type SourceType = (typeof SOURCE_TYPE)[number];

export interface StateType {
  hvAvData: ErLoad & { data: HvAvData[] | null };
  hvAvDelete: ErLoad & { data: DeleteHvAvDataResponse | null };
}

export type HvAvData = {
  id: number;
  uploaded_at: string;
  client_id: number;
  upload: number;
  date_from: string;
  date_to: string;
  source_id: number;
  profile: string;
  region: string;
  callbacks: number;
  finish_of_publication: string;
  total_requests: number;
  callbacks_price: number;
  services_price: number;
  days: number;
  publication: number;
  total_price: null;
  publication_date: string;
  publication_month: string;
  region_of_vacation: null;
  address: null;
  category: null;
  parameter: null;
  subcategory: null;
  first_publication_date: null;
  views: null;
  bonus_price_of_callbacks: null;
  price_of_publication: null;
  price_without_bonuses: null;
  favorites: null;
  manager: null;
};

export type FetchHvAvDataParams = {
  client_id: string;
  source_type: SourceType;
  date_from?: string;
  date_to?: string;
  uploaded_from?: string;
  uploaded_to?: string;
  profile?: string;
  region?: string;
  limit?: string;
  excel?: string;
};

export type DeleteHvAvDataParams = {
  source_type: SourceType;
  source_id: string;
};

export type DeleteHvAvDataResponse = {
  response: {
    message?: string;
    error?: string;
  };
};
