import { adaptDate } from "components/UI/DatePicker/helpers";

export const handleDate = (
  date: Date | string,
  withTime: boolean = false,
  isRU: boolean = false,
): string => {
  if (date === undefined) return "";

  let stringDateTime: string;
  if (typeof date === "string") {
    if (date === "") return "";
    stringDateTime = date;
  } else stringDateTime = adaptDate(date)?.toISOString() ?? "";

  let result = stringDateTime.slice(0, 10);
  if (isRU) result = result.split("-").reverse().join(".");
  if (withTime) result += ` ${stringDateTime.split("T")[1]?.slice(0, 5)}`;

  return result;
};

export const getOffsetDate = (offsetDays: number): Date =>
  new Date(Date.now() + offsetDays * 24 * 60 * 60 * 1000);
