import { DOWNLOAD_FILE } from "common/const";
import { STATUS_VARIANTS } from "common/types";

export interface Column {
  id: "file" | "date" | "status" | "media" | "download" | "filteredMedia";
  label: string;
}

export interface MockedRow {
  file: { name: string; link: string };
  date: string;
  status: { info: string; variant: STATUS_VARIANTS };
  media: { name: string; link: string };
}

export const columns: Column[] = [
  { id: "file", label: "План подбора" },
  {
    id: "date",
    label: "Дата загрузки плана подбора",
  },
  {
    id: "status",
    label: "Имя загрузившего", // TODO: add header-switcher depending on data - Статус медиаплана or Имя загрузившего
  },
  {
    id: "media",
    label: "Медиаплан",
  },
  {
    id: "download",
    label: "Скачать медиаплан",
  },
  {
    id: "filteredMedia",
    label: "Медиапланы [Имя клиента]",
  },
];

const rowsDataSample: MockedRow[] = [
  {
    file: {
      name: "plan-podbora4.xlsx",
      link: DOWNLOAD_FILE,
    },
    date: "12.01.2023 21:23",
    status: {
      variant: "done",
      info: "Создано 12.01.2023 23:30",
    },
    media: {
      name: "Mediaplan_12.01.2023",
      link: "Mediaplan_12.01.2023",
    },
  },
  {
    file: {
      name: "plan-podbora3.xlsx",
      link: DOWNLOAD_FILE,
    },
    date: "11.01.2023 21:23",
    status: {
      variant: "process",
      info: "В обработке",
    },
    media: {
      name: "Mediaplan_11.01.2023",
      link: "Mediaplan_11.01.2023",
    },
  },
  {
    file: {
      name: "plan-podbora2.xlsx",
      link: DOWNLOAD_FILE,
    },
    date: "10.01.2023 21:23",
    status: {
      variant: "process",
      info: "В обработке",
    },
    media: {
      name: "Mediaplan_10.01.2023",
      link: "Mediaplan_10.01.2023",
    },
  },
  {
    file: {
      name: "plan-podbora1.xlsx",
      link: DOWNLOAD_FILE,
    },
    date: "09.01.2023 21:23",
    status: {
      variant: "process",
      info: "В обработке",
    },
    media: {
      name: "Mediaplan_09.01.2023",
      link: "Mediaplan_09.01.2023",
    },
  },
  {
    file: {
      name: "plan-podbora0.xlsx",
      link: DOWNLOAD_FILE,
    },
    date: "08.01.2023 21:23",
    status: {
      variant: "process",
      info: "В обработке",
    },
    media: {
      name: "Mediaplan_08.01.2023",
      link: "Mediaplan_08.01.2023",
    },
  },
  {
    file: {
      name: "plan-podbora-1.xlsx",
      link: DOWNLOAD_FILE,
    },
    date: "07.01.2023 21:23",
    status: {
      variant: "done",
      info: "Создано 07.01.2023 23:30",
    },
    media: {
      name: "Mediaplan_07.01.2023",
      link: "Mediaplan_07.01.2023",
    },
  },
  {
    file: {
      name: "plan-podbora-2.xlsx",
      link: DOWNLOAD_FILE,
    },
    date: "06.01.2023 21:23",
    status: {
      variant: "error",
      info: "Ошибка",
    },
    media: {
      name: "Mediaplan_06.01.2023",
      link: "Mediaplan_06.01.2023",
    },
  },
];

export const mockedData: MockedRow[] = [];

for (let i = 0; i < 50; i++) {
  mockedData.push(...rowsDataSample);
}
