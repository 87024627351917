import { useEffect, useMemo, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { uploadFile } from "api/Rocket";
import { AxiosResponse } from "axios";
import {
  EMPTY_DATA,
  EXCEL_ACCEPT_FORMAT,
  MEDIAPLANS_FILTERS,
  SUCCESS_FILE_UPLOAD,
  TEMPLATE_PLAN_UPLOAD_URL,
} from "common/const";
import { useModal } from "common/hooks/useModal";
import useNotify from "common/hooks/useNotify";
import { useUploadHandler } from "common/hooks/useUploadHandler";
import { UploadMessage, UploadResponse } from "common/interfaces";
import { addOptionalParams } from "common/utils/addOptionalParams";
import hasFullAccess from "common/utils/checkRole";
import { handleDate } from "common/utils/handleDate";
import { checkOnPositiveInteger } from "common/utils/zodValidators";
import { ConfigForm, FormDataConfig } from "components/ConfigMediaplan";
import { Filters, FilterValues } from "components/Filters";
import MediaPlanTable from "components/tables/MediaPlanTable";
import PlanUploadsTable from "components/tables/PlanUploadsTable";
import { H1, H2, H3 } from "components/typography";
import { Button } from "components/UI/Button";
import HorizontalLine from "components/UI/HorizontalLine";
import { Loader } from "components/UI/Loader";
import { Message } from "components/UI/Message";
import { Modal } from "components/UI/Modal";
import Upload from "components/UI/Upload";
import { setGlobalParams } from "services/store/reducers/globalParams";
import {
  fetchMediaPlans,
  fetchPlanUploads,
  postUltimateUpload,
  postUploadConfig,
} from "services/store/reducers/media";
import {
  FetchMediaUploadsParams,
  SendUltimateUploadParams,
} from "services/store/reducers/media/types";
import { useAppDispatch, useAppSelector } from "services/store/store";
import useSessionStorageState from "use-session-storage-state";
import { SafeParseReturnType } from "zod";

import commonStyles from "../common-styles.module.sass";
import { getNameById } from "./helpers";
import styles from "./styles.module.sass";

const MediaPage: React.FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const {
    mediaUploadsData,
    mediaUploadsLoading,
    planUploadsData,
    planUploadsLoading,
    ultimateUploadLoading,
  } = useAppSelector((state) => state.mediaplan);

  const {
    uploaderName: uploader_name,
    clientId: client_id,
    role: stateRole,
  } = useAppSelector((state) => state.globalParams);

  const [isOpenUploads, setIsOpenUploads] = useState(false);
  const [ultimateUploadRequest, setUltimateUploadRequest] =
    useState<SendUltimateUploadParams | null>(null);
  const [isPlanLoading, setPlanLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>();

  const [uploadMessage, setUploadMessage] =
    useSessionStorageState<UploadMessage | null>("upload-message-media");
  const { fileName, file, handleUpload } = useUploadHandler();
  const { ToastContainer, notifyUploading } = useNotify();
  const { isOpen, openModal, closeModal } = useModal();
  const uploadRef = useRef<HTMLInputElement>(null);
  const downloadRef = useRef<HTMLAnchorElement>(null);

  const { uploaderName, clientId, role } = useMemo(() => {
    const urlParams = new URLSearchParams(location.search);
    const clientId = parseInt(urlParams.get("client_id") ?? "0", 10);

    return {
      uploaderName: urlParams.get("uploader_name"),
      clientId,
      clientName: urlParams.get("client_name") ?? getNameById(clientId),
      role: "admin",
    };
  }, [location.search]);

  const handleOpenUploads = () => {
    dispatch(
      fetchPlanUploads({
        client_id,
      }),
    );
    setIsOpenUploads(true);
  };
  const handleCloseUploads = () => setIsOpenUploads(false);

  const handleSubmit = (formData: FormDataConfig) => {
    console.log("Данные формы:", formData);
    sendUploadConfig(formData);
    closeModal();
    handleOpenUploads();
  };

  const handleUploadClick = (): void => {
    if (uploadRef.current) uploadRef.current.click();
  };

  const handleDownloadClick = (): void => {
    if (downloadRef.current) downloadRef.current.click();
  };

  const handleSaveClick = (): void => {
    if (ultimateUploadRequest && !ultimateUploadLoading) {
      dispatch(postUltimateUpload(ultimateUploadRequest));
    }
  };

  const sendUploadConfig = (formData) => {
    const payload = {
      ...formData,
      uploader_name: uploader_name,
      client_id,
      client_name: getNameById(client_id),
      date_from: handleDate(formData.date_from || new Date()),
      date_to: handleDate(formData.date_to || new Date()),
      mp_start: handleDate(formData.mp_start || new Date()),
      mp_finish: handleDate(formData.mp_finish || new Date()),
      sr_start: handleDate(new Date("2023-01-01")),
      sr_finish: handleDate(new Date()),
      sr_upload: handleDate(new Date()),
      prev_mp_start: handleDate(formData.prev_mp_start || new Date()),
      prev_mp_finish: handleDate(formData.prev_mp_finish || new Date()),
    };
    dispatch(postUploadConfig(payload));
  };

  const handleUpdateFilters = (filterValues: FilterValues): void => {
    const sendParams: FetchMediaUploadsParams = {
      client_id: clientId,
    };
    addOptionalParams(sendParams, filterValues);

    let result: SafeParseReturnType<number, number> | undefined;
    result = checkOnPositiveInteger(
      filterValues.id,
      MEDIAPLANS_FILTERS.errorsPhrases.id,
    );
    const errorMessage = result?.error?.issues[0].message;

    if (errorMessage) {
      setErrors([errorMessage]);
      return;
    }

    setErrors(undefined);
    dispatch(fetchMediaPlans(sendParams));
  };

  useEffect(() => {
    dispatch(fetchMediaPlans({ client_id }));
  }, [dispatch, client_id]);

  useEffect(() => {
    if (file && uploader_name) {
      setPlanLoading(true);
      setUploadMessage(null);
      const formData: FormData = new FormData(); // TODO: get this from reducers
      const params: URLSearchParams = new URLSearchParams({
        client_id: client_id.toString(),
        uploader_name: uploader_name,
      });
      const endpoint: string = `/recruitment-plan/upload-recruitment-plan?${params.toString()}`;

      formData.append("file", file);

      uploadFile<UploadResponse>(endpoint, formData)
        .then((response: AxiosResponse<UploadResponse>) => {
          if (
            "error" in response.data.response &&
            response.data.response.error
          ) {
            notifyUploading(fileName, "error");
            setUploadMessage({
              message: `${fileName} : ${response.data.response.error}`,
              success: false,
            });
          } else {
            dispatch(
              fetchPlanUploads({
                client_id,
              }),
            );
            notifyUploading(fileName, "success");
            setUploadMessage({
              message: `${fileName} : ${SUCCESS_FILE_UPLOAD}`,
              success: true,
            });
          }
        })
        .catch((error: Error) => {
          setUploadMessage({
            message: `${fileName} : ${error.message}`,
            success: false,
          });
        })
        .finally(() => {
          setPlanLoading(false);
        });
    }
  }, [
    dispatch,
    file,
    uploader_name,
    client_id,
    notifyUploading,
    setUploadMessage,
    fileName,
  ]);

  useEffect(() => {
    if (!ultimateUploadLoading) handleCloseUploads();
  }, [ultimateUploadLoading]);

  useEffect(() => {
    if (uploaderName && clientId && role) {
      dispatch(setGlobalParams({ uploaderName, clientId, role }));
    }
  }, [dispatch, uploaderName, clientId, role]);

  return (
    <>
      <section className={commonStyles.heading}>
        <H1 color="#0C3953">Медиапланы</H1>
        <Filters
          onUpdateFilters={handleUpdateFilters}
          configuration={MEDIAPLANS_FILTERS}
          errors={errors}
        />
      </section>
      {hasFullAccess(stateRole) ? (
        <>
          <section className={styles.selectionPlan}>
            <H3 color="#0C3953">План подбора</H3>
            <Link
              ref={downloadRef}
              to={TEMPLATE_PLAN_UPLOAD_URL}
              style={{ display: "none" }}
            />
          </section>
          <Button onClick={openModal}>Выбор плана подбора</Button>
          <HorizontalLine />
        </>
      ) : (
        <></>
      )}
      <section className={styles.wrapper}>
        {mediaUploadsLoading ? (
          <Loader />
        ) : (
          <MediaPlanTable data={mediaUploadsData} />
        )}
        <div>
          <Modal open={isOpen} onClose={closeModal}>
            <div>
              <H2>Заполните конфигурацию</H2>
              <ConfigForm onSubmit={handleSubmit} />
            </div>
          </Modal>
          <Modal open={isOpenUploads} onClose={handleCloseUploads}>
            <div className={styles.content}>
              <H2>Выберите план подбора</H2>
              {planUploadsLoading ? (
                <Loader />
              ) : planUploadsData ? (
                <PlanUploadsTable
                  data={planUploadsData}
                  choosePlan={setUltimateUploadRequest}
                />
              ) : (
                EMPTY_DATA
              )}
              <div className={styles.newPlan}>
                <Upload
                  forwardRef={uploadRef}
                  id="upload"
                  onUpload={handleUpload}
                  accept={EXCEL_ACCEPT_FORMAT}
                  label={fileName}
                >
                  <Button
                    variant="upload"
                    loading={isPlanLoading}
                    disabled={isPlanLoading}
                    onClick={handleUploadClick}
                  >
                    Добавьте новый
                  </Button>
                </Upload>
                <Button variant="download" onClick={handleDownloadClick}>
                  Скачать шаблон XLSX
                </Button>
              </div>
              {uploadMessage && (
                <Message
                  text={uploadMessage.message}
                  success={uploadMessage.success}
                />
              )}{" "}
              <Button
                variant="primary"
                loading={ultimateUploadLoading}
                disabled={ultimateUploadLoading}
                onClick={handleSaveClick}
              >
                Сохранить
              </Button>
            </div>
          </Modal>
        </div>
        <ToastContainer />
      </section>
    </>
  );
};
export default MediaPage;
