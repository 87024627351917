import { ChangeEvent } from "react";

import styles from "./styles.module.sass";

interface RateProps {
  value?: number | string | undefined;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  style?: React.CSSProperties;
  error?: string;
}

const RateInput: React.FC<RateProps> = ({
  value,
  onChange,
  type = "number",
  style,
  error,
}): JSX.Element => {
  return (
    <>
      <label className={styles.pencil}>
        <input
          type={type}
          value={value ?? ""}
          placeholder="..."
          className={styles.rate}
          onChange={onChange}
          style={style}
        />
      </label>
      {error && <div className={styles.error}>{error}</div>}
    </>
  );
};

export default RateInput;
