import { Outlet } from "react-router";
import SideBar from "components/SideBar";

import styles from "./styles.module.sass";

const Layout: React.FC = (): JSX.Element => {
  return (
    <div className={styles.container}>
      <SideBar />
      <main className={styles.main}>
        <Outlet />
      </main>
    </div>
  );
};

export default Layout;
