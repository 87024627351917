export function capitalizeFirstLetter(string: string): string {
  return string[0].toUpperCase() + string.slice(1);
}

export function formatCityName(cityName: string): string {
  return cityName
    .split(" ")
    .map(capitalizeFirstLetter)
    .join(" ")
    .split("-")
    .map((word, index, parts) =>
      parts.length === 3 && index === 1 ? word : capitalizeFirstLetter(word),
    )
    .join("-");
}
