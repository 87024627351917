import classNames from "classnames";
import React from "react";

import MUIDrawer from "@mui/material/Drawer";

import styles from "./styles.module.sass";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

type Anchor = "top" | "left" | "bottom" | "right";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  anchor: Anchor;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Drawer = React.forwardRef<HTMLDivElement, Props>(
  ({ anchor, isOpen, setIsOpen, className, children, ...props }, ref) => {
    const toggleDrawer =
      (open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent): void => {
        if (
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }

        setIsOpen(open);
      };

    const combinedClassName = classNames(styles.drawer, className);

    return (
      <MUIDrawer
        ref={ref}
        anchor={anchor}
        open={isOpen}
        onClose={toggleDrawer(false)}
        {...props}
      >
        <div className={combinedClassName}>
          <IconButton
            aria-label="close"
            onClick={toggleDrawer(false)}
            sx={{
              position: "absolute",
              right: 32,
              top: 32,
            }}
          >
            <CloseIcon />
          </IconButton>
          {children}
        </div>
      </MUIDrawer>
    );
  }
);
