import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router";

import DownloadIcon from "@mui/icons-material/Download";

import {
  EMPTY_DATA,
  MEDIA_PLAN_CAPTIONS,
  MEDIA_PLAN_COLUMNS,
  MEDIAPLAN_FILTERS,
} from "common/const";
import hasFullAccess from "common/utils/checkRole";
import { handleParams } from "common/utils/handleParams";
import { Filters, FilterValues } from "components/Filters";
import AdditionalFiltersPanel, {
  AdditionalFilters,
} from "components/Filters/AdditionalFilters";
import MediaPlanUploadTable from "components/tables/MediaPlanUploadTable";
import { H1 } from "components/typography";
import { BreadCrumbs } from "components/UI/BreadCrumbs";
import { FloatingLabelInput } from "components/UI/FloatingLabelInput";
import { HiddenLink } from "components/UI/HiddenLink";
import { Loader } from "components/UI/Loader";
import { MUIIconButton } from "components/UI/MUIIconButton";
import { fetchAndDownloadFile } from "pages/media-plan/helpers";
import { fetchMediaPlan } from "services/store/reducers/media";
import { MediaPlanUpload } from "services/store/reducers/media/types";
import { useAppDispatch, useAppSelector } from "services/store/store";
import useSessionStorageState from "use-session-storage-state";

import {
  checkByLimits,
  convertToNumbers,
} from "../../common/utils/zodValidators";
import commonStyles from "../common-styles.module.sass";
import { filterMediaPlan } from "./helpers";
import styles from "./styles.module.sass";

const MediaPlanClientsPage: React.FC = (): JSX.Element => {
  const {
    mediaPlanFilters,
    mediaPlanUploadData,
    mediaPlanUploadLoading,
    mediaPlanSum,
  } = useAppSelector((state) => state.mediaplan);
  const { clientId } = useAppSelector((state) => state.globalParams);

  const { role } = useAppSelector((state) => state.globalParams);

  const dispatch = useAppDispatch();

  const [errors, setErrors] = useState<string[]>();
  const [mediaPlan, setMediaPlan] = useState<MediaPlanUpload[] | null>(
    mediaPlanUploadData,
  );

  const [additionalFilters, setAdditionalFilters] =
    useSessionStorageState<AdditionalFilters>("additional-filters");

  const downloadRef = useRef<HTMLAnchorElement>(null);
  const { search } = useLocation();

  const { id, uploader_name } = useMemo(() => {
    const urlParams = new URLSearchParams(search);
    return {
      id: Number(urlParams.get("id")),
      uploader_name: urlParams.get("uploader_name") ?? "",
    };
  }, [search]);

  const handleGetExcel = (): void => {
    let endpoint = `/media-plan/get-clients-media-plan/${id}`;
    let params = `?filter_uploader=${uploader_name}`;
    if (mediaPlanFilters) {
      const nonEmptyFilters = Object.fromEntries(
        Object.entries(mediaPlanFilters).filter(
          ([_, value]) => value !== null && value !== undefined,
        ),
      );
      params += handleParams(nonEmptyFilters, ["filter_uploader"], true);
    }
    endpoint += params;
    fetchAndDownloadFile(endpoint, downloadRef);
  };

  const handleUpdateFilters = (filterValues: FilterValues): void => {
    const result = checkByLimits(
      convertToNumbers(filterValues),
      MEDIAPLAN_FILTERS,
    );
    const errors = result
      .filter((error) => !error.success)
      .map((error) => (error.error ? error.error.issues[0].message : ""));
    if (errors.length) {
      setErrors(errors);
      return;
    }
    setErrors(undefined);
    dispatch(
      fetchMediaPlan({
        upload: id,
        filter_uploader: uploader_name,
        amount_on_start: Number(filterValues.amount_on_start),
        hire_chance: Number(filterValues.hire_chance),
        price_for_lead: Number(filterValues.price_for_lead),
        registered_price: Number(filterValues.registered_price),
      }),
    );
  };

  useEffect(() => {
    if (mediaPlanUploadData)
      setMediaPlan(
        additionalFilters
          ? filterMediaPlan(mediaPlanUploadData, additionalFilters)
          : mediaPlanUploadData,
      );
  }, [additionalFilters, mediaPlanUploadData]);

  useEffect(() => {
    dispatch(fetchMediaPlan({ upload: id, filter_uploader: uploader_name }));
  }, [dispatch, id, uploader_name]);

  return (
    <>
      <BreadCrumbs
        breadCrumbs={[{ text: "Медиапланы", link: `/mediaplans` }]}
        current={`Медиаплан №${id}`}
      />
      <section className={commonStyles.heading}>
        <H1 color="#0C3953">Медиаплан №{id} </H1>
        <HiddenLink ref={downloadRef} />
        <MUIIconButton
          icon={
            <DownloadIcon className={commonStyles.download} color="success" />
          }
          onClick={handleGetExcel}
        />
        {hasFullAccess(role) && (
          <Filters
            onUpdateFilters={handleUpdateFilters}
            apiFilters={mediaPlanFilters}
            configuration={MEDIAPLAN_FILTERS}
            errors={errors}
          >
            <AdditionalFiltersPanel
              handleUpdate={setAdditionalFilters}
              defaultFilterValues={additionalFilters}
            />
          </Filters>
        )}
      </section>
      <section className={styles.sum}>
        <FloatingLabelInput
          label="Бюджет"
          value={mediaPlanSum?.amount_on_start.toString()}
          readonly
        />
        <FloatingLabelInput
          label="Количество лидов"
          value={mediaPlanSum?.leads_for_week.toString()}
          readonly
        />
        <FloatingLabelInput
          label="Количество оформленных за месяц"
          value={mediaPlanSum?.future_registered.toString()}
          inputClassName={styles.wider}
          readonly
        />
      </section>
      <section>
        {mediaPlanUploadLoading ? (
          <Loader />
        ) : mediaPlan ? (
          <MediaPlanUploadTable
            data={mediaPlan}
            columns={MEDIA_PLAN_COLUMNS}
            captions={MEDIA_PLAN_CAPTIONS}
            isUniqueClient={clientId === 64}
          />
        ) : (
          EMPTY_DATA
        )}
      </section>
    </>
  );
};
export default MediaPlanClientsPage;
