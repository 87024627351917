import { useState } from "react";
import { useLocation, useNavigate } from "react-router";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import { TAB_ROUTES } from "common/const";
import { getLocationIndex } from "common/utils/getLocationIndex";
import { IssuedPage, MediaPage, SelectionPage, WSDataPage } from "pages";

import styles from "./styles.module.sass";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabContents: JSX.Element[] = [
  <MediaPage />,
  <IssuedPage />,
  <WSDataPage />,
  <SelectionPage />,
];

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box className={styles.tabPanel}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs(): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState<number>(
    getLocationIndex(location.pathname, TAB_ROUTES),
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedIndex(newValue);
    navigate(TAB_ROUTES[newValue].value);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={selectedIndex}
          onChange={handleChange}
          aria-label="basic tabs"
        >
          {TAB_ROUTES.map((value, index) => (
            <Tab
              label={value.name}
              key={index}
              {...a11yProps(index)}
              style={{ textTransform: "none" }}
            />
          ))}
        </Tabs>
      </Box>
      {TabContents.map((tab, index) => (
        <CustomTabPanel key={index} value={selectedIndex} index={index}>
          {tab}
        </CustomTabPanel>
      ))}
    </Box>
  );
}
