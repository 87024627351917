interface Column {
  id: "planId" | "date" | "author";
  label: string;
}

export interface Row {
  planId: number;
  date: string;
  author: string;
}

export const columns: readonly Column[] = [
  { id: "planId", label: "План подбора" },
  {
    id: "date",
    label: "Дата загрузки плана подбора",
  },
  {
    id: "author",
    label: "Имя загрузившего",
  },
];
