import { useCallback, useEffect, useMemo, useState } from "react";

import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import { useValidateSourceId } from "common/hooks/validation/useValidateSourceId";
import { hasError } from "common/hooks/validation/useValidateSourceId/utils";
import { SettingSource } from "services/store/reducers/settings/types";

import BasicSelect from "../UI/Select";
import Rate from "./components/RateInput";
import {
  DIRECTION_OPTIONS,
  DIRECTION_TARIFF_TYPE,
  SOURCE_OPTIONS_SETTING,
} from "./const";
import { CustomTableHead, getOptions } from "./helpers";
import styles from "./styles.module.sass";
import { Data, Rates } from "./types";

interface SettingsTableProps {
  rates: Rates;
  setRates: React.Dispatch<React.SetStateAction<Rates>>;
  selected: readonly number[];
  setSelected: React.Dispatch<React.SetStateAction<readonly number[]>>;
  errors?: number[];
}

export const SettingsTable: React.FC<SettingsTableProps> = ({
  rates,
  setRates,
  selected,
  setSelected,
  errors,
}): JSX.Element => {
  const [errorRows, setErrorRows] = useState<number[]>([]);
  const [validateErrors, validateAndUpdate] = useValidateSourceId({});

  const handleChange = useCallback(
    (id: number, field: keyof SettingSource, value: any) => {
      // Проверяем, является ли value событием
      const newValue = value && value.target ? value.target.value : value;

      validateAndUpdate(id, field, newValue);

      setRates((prevRates) => ({
        ...prevRates,
        [id]: {
          ...prevRates[id],
          [field]: newValue,
        },
      }));
    },
    [setRates, validateAndUpdate],
  );

  const rows: Data[] = useMemo(
    () =>
      Object.entries(rates).map(([id, data]) => ({
        id: parseInt(id),
        source_id: (
          <Rate
            onChange={(event) => handleChange(parseInt(id), "source_id", event)}
            value={data.source_id}
            error={
              hasError(validateErrors, Number(id), "source_id") &&
              validateErrors[id].source_id
            }
          />
        ),
        search_type: (
          <BasicSelect
            options={DIRECTION_OPTIONS}
            variant="standard"
            select={data.search_type || ""}
            setSelect={(name) =>
              handleChange(parseInt(id, 10), "search_type", name)
            }
            sx={{ height: 36, p: 0 }}
          />
        ),
        source: (
          <BasicSelect
            options={SOURCE_OPTIONS_SETTING}
            variant="standard"
            select={data.source || ""}
            setSelect={(name) => handleChange(parseInt(id, 10), "source", name)}
            sx={{ height: 36, p: 0 }}
          />
        ),
        additional: (
          <BasicSelect
            options={getOptions(data.source)}
            variant="standard"
            select={data.additional || ""}
            setSelect={(name) =>
              handleChange(parseInt(id, 10), "additional", name)
            }
            sx={{ height: 36, p: 0 }}
          />
        ),
        tariff_type: (
          <BasicSelect
            options={DIRECTION_TARIFF_TYPE}
            variant="standard"
            select={data.tariff_type || ""}
            setSelect={(name) =>
              handleChange(parseInt(id, 10), "tariff_type", name)
            }
            sx={{ height: 36, p: 0 }}
          />
        ),
        price: (
          <Rate
            onChange={(event) => handleChange(parseInt(id), "price", event)}
            value={data.price}
          />
        ),

        use: (
          <Checkbox
            checked={data.use === 1}
            onChange={(event) =>
              handleChange(parseInt(id), "use", event.target.checked ? 1 : 0)
            }
            inputProps={{ "aria-label": "controlled" }}
          />
        ),
      })),
    [rates, handleChange, validateErrors],
  );

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  useEffect(() => {
    if (errors) setErrorRows(errors);
  }, [errors]);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper className={styles.accommodation}>
        <TableContainer>
          <Table aria-labelledby="tableTitle">
            <CustomTableHead />
            <TableBody>
              {rows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    className={`${styles.row} ${
                      errorRows.includes(row.id) && styles.error
                    }`}
                  >
                    <TableCell className={styles.cell} padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                        onClick={(event) => handleClick(event, row.id)}
                      />
                    </TableCell>

                    <TableCell className={styles.cell}>
                      {row.source_id}
                    </TableCell>
                    <TableCell className={styles.cell}>
                      {row.search_type}
                    </TableCell>
                    <TableCell className={styles.cell}>{row.source}</TableCell>
                    <TableCell className={styles.cell}>
                      {row.additional}
                    </TableCell>
                    <TableCell className={styles.cell}>
                      {row.tariff_type}
                    </TableCell>
                    <TableCell className={styles.cell}>{row.price}</TableCell>
                    <TableCell className={styles.cell}>{row.use}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};
