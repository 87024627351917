import React, { HTMLInputTypeAttribute, useEffect, useState } from "react";

import { FilterConfiguration, FILTERS_INFO } from "common/const";
import { FloatingLabelInput } from "components/UI/FloatingLabelInput";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  value: number | string;
  onFilterChange: (
    newValue: number | number[] | string,
    filter: string,
  ) => void;
  id: string;
  configuration: FilterConfiguration;
  label: string;
  isErrored?: boolean;
  isMultipleEnter?: boolean;
  type?: HTMLInputTypeAttribute;
  lowerCase?: boolean;
}

export const Input = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      className,
      value,
      onFilterChange,
      id,
      configuration,
      label,
      isErrored = false,
      isMultipleEnter = false,
      type,
      lowerCase = false,
      ...props
    },
    ref,
  ) => {
    const [tooltip, setTooltip] = useState<string>();

    useEffect(() => {
      if (isMultipleEnter) setTooltip(FILTERS_INFO.delimiter);
    }, [isMultipleEnter]);

    return (
      <FloatingLabelInput
        ref={ref}
        value={value ? value.toString() : ""}
        onInputChange={(_, newValue) =>
          onFilterChange(
            lowerCase ? newValue.toString().toLowerCase() : newValue,
            id,
          )
        }
        label={label}
        isError={isErrored}
        tooltip={tooltip}
        className={className}
        type={type}
        {...props}
      />
    );
  },
);
