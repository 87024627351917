import React, { Fragment } from "react";

import { FilterConfiguration } from "common/const";

import { FilterValues } from "..";
import { DatePicker } from "../DatePicker";
import { Input } from "../Input";
import { InputSlider } from "../InputSlider";
import { Select } from "../Select";
import styles from "./styles.module.sass";

interface FilterManagersProps extends React.HTMLAttributes<HTMLDivElement> {
  configuration: FilterConfiguration;
  values: FilterValues;
  onFilterChanged: (
    newValue: number | number[] | string,
    filter: string,
  ) => void;
  errors?: string[];
}

export const FilterManagers: React.FC<FilterManagersProps> = ({
  configuration,
  values,
  onFilterChanged,
  errors,
  className,
  ...props
}) => {
  const renderGroupedManagers = (groups: {
    [key: number]: string[];
  }): JSX.Element => (
    <>
      {Object.keys(groups).map((key) => (
        <div key={key} className={styles.group}>
          {renderManagers(groups[key])}
        </div>
      ))}
    </>
  );
  const renderManagers = (keys: string[]): JSX.Element => (
    <>
      {keys.map((key) => {
        // общие параметры каждого фильтра
        const label: string = configuration.labels[key];
        const value: string | number = values[key];

        let isErrored = false;
        errors?.forEach((error) => {
          if (error.includes(configuration.errorsPhrases[key]))
            isErrored = true;
        });

        // отрисовка
        let filter: JSX.Element;

        switch (configuration.types[key]) {
          case "slider":
            filter = (
              <InputSlider
                onFilterChange={onFilterChanged}
                value={value}
                id={key}
                configuration={configuration}
                label={label}
                isErrored={isErrored}
              />
            );
            break;
          case "select":
            filter = (
              <Select
                onFilterChange={onFilterChanged}
                value={value}
                id={key}
                configuration={configuration}
                label={label}
                isErrored={isErrored}
              />
            );
            break;
          case "date":
            filter = (
              <DatePicker
                value={value}
                onFilterChange={onFilterChanged}
                id={key}
                configuration={configuration}
                label={label}
                isErrored={isErrored}
              />
            );
            break;
          case "input":
            filter = (
              <Input
                onFilterChange={onFilterChanged}
                value={value}
                id={key}
                configuration={configuration}
                label={label}
                isErrored={isErrored}
                className={styles.textInput}
                lowerCase
              />
            );
            break;
          case "inputNumber":
            filter = (
              <Input
                onFilterChange={onFilterChanged}
                value={value}
                id={key}
                configuration={configuration}
                label={label}
                isErrored={isErrored}
                className={styles.textInput}
                type="number"
              />
            );
            break;
          default:
            filter = <></>;
        }

        return <Fragment key={key}>{filter}</Fragment>;
      })}
    </>
  );

  return (
    <div className={`${styles.filterManagers} ${className}`} {...props}>
      {configuration.groups
        ? renderGroupedManagers(configuration.groups)
        : renderManagers(Object.keys(configuration.labels))}
    </div>
  );
};
