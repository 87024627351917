import { optionType } from "components/UI/Select";

import { formatCityName } from "./formatText";

export const convertToOptions = (
  values: string[],
  options: {
    isCities?: boolean;
    hasDefaultValue?: boolean;
  } = { isCities: true, hasDefaultValue: true },
): optionType[] => {
  const { isCities, hasDefaultValue } = options;
  const result = values.map((value) => ({
    name: isCities ? formatCityName(value) : value,
    value: value,
  }));
  if (hasDefaultValue) result.unshift({ name: "Не выбрано", value: "" });
  return result;
};
