import React from "react";

import classNames from "classnames";

import styles from "./styles.module.sass";

interface HorizontalLineProps extends React.HTMLAttributes<HTMLSpanElement> {}

const HorizontalLine: React.FC<HorizontalLineProps> = ({
  className,
  ...props
}) => {
  const HLClasses = classNames(styles.line, className);
  return <span className={HLClasses} {...props}></span>;
};

export default HorizontalLine;
