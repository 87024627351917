import styled from '@emotion/styled';

import {TTextProps} from './types';

export default styled.h3<TTextProps>`
    font-size: 1rem;
    text-align: ${({align}) => align ?? 'left'};
    color: ${({color, theme}) => color ?? theme.colors.text.superDark};
    font-weight: 600;
`;
