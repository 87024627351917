import { createSlice } from "@reduxjs/toolkit";

export const globalParamsSlice = createSlice({
  name: "globalParams",
  initialState: {
    uploaderName: "",
    clientId: 0,
    role: "client",
  },
  reducers: {
    setGlobalParams: (state, action) => {
      state.uploaderName = action.payload.uploaderName;
      state.clientId = action.payload.clientId;
      state.role = action.payload.role;
    },
  },
});

export const { setGlobalParams } = globalParamsSlice.actions;
export const globalParamsReducer = globalParamsSlice.reducer;
