import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import {
  AVITO_OPTIONS,
  headCells,
  HH_OPTIONS,
  RABOTA_OPTIONS,
  SUPERJOB_OPTIONS,
  ZARPLATA_OPTIONS,
} from "./const";
import styles from "./styles.module.sass";

export const generateInitialState = (count) => {
  const stateTemplate = {
    sourceId: undefined,
    searchType: undefined,
    source: undefined,
    additional: undefined,
    tariffType: undefined,
    accessPrice: undefined,
    price: undefined,
    use: undefined,
  };

  return Array.from({ length: count }, (_, index) => index + 1).reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: { ...stateTemplate },
    }),
    {},
  );
};

export function CustomTableHead() {
  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          className={styles.headerBorder}
        ></TableCell>
        {headCells.map((headCell, id) => (
          <TableCell
            className={`${styles.header} ${styles.headerBorder}`}
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
            sx={{ width: id ? "170px" : "260px" }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export function getOptions(source?: string) {
  switch (source) {
    case "Avito":
      return AVITO_OPTIONS;
    case "rabota":
      return RABOTA_OPTIONS;
    case "zarplata":
      return ZARPLATA_OPTIONS;
    case "superjob":
      return SUPERJOB_OPTIONS;
    default:
      return HH_OPTIONS;
  }
}
