import React from "react";

import classNames from "classnames";
import { FilterConfiguration } from "common/const";
import BasicSelect from "components/UI/Select";

import styles from "./styles.module.sass";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  value: number | string;
  onFilterChange: (newValue: string, filter: string) => void;
  id: string;
  configuration: FilterConfiguration;
  label: string;
  isErrored?: boolean;
}

export const Select = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      className,
      value,
      onFilterChange,
      id,
      configuration,
      label,
      isErrored = false,
      ...props
    },
    ref,
  ) => {
    const combinedClassName = classNames(className);
    return (
      <div ref={ref} className={combinedClassName} {...props}>
        {configuration.selectOptions && (
          <>
            <BasicSelect
              options={configuration.selectOptions[id]}
              select={value.toString()}
              setSelect={(newValue) => {
                onFilterChange(newValue, id);
              }}
              variant="standard"
              label={label}
              className={isErrored ? styles.error : ""}
              sx={{ width: 255 }}
            />
          </>
        )}
      </div>
    );
  },
);
