import { AdditionalFilters } from "components/Filters/AdditionalFilters";
import { MediaPlanUpload } from "services/store/reducers/media/types";

export const filterMediaPlan = (
  data: MediaPlanUpload[],
  filters: AdditionalFilters,
): MediaPlanUpload[] =>
  data.filter((row) =>
    Object.keys(filters).every((key) =>
      filters[key] ? row[key].includes(filters[key]) : true,
    ),
  );
