import React, { useMemo } from "react";
import DownloadIcon from "@mui/icons-material/Download";

interface DownloadButtonProps {
  filePath: string;
}

const transformFilePath = (filePath: string | null): string => {
  if (filePath && filePath.startsWith(".")) {
    return `https://api.hrrocket.ru${filePath.substring(1)}`;
  }
  return "";
};

const DownloadButton: React.FC<DownloadButtonProps> = ({ filePath }) => {
  const transformedFilePath = useMemo(
    () => transformFilePath(filePath),
    [filePath]
  );

  return (
    <a href={transformedFilePath} download style={{ cursor: "pointer" }}>
      <DownloadIcon color="success" />
    </a>
  );
};

export default DownloadButton;
