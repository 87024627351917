import React from "react";

import { Button } from "../Button";

import classNames from "classnames";
import { getPages } from "common/utils/getPages";

import styles from "./styles.module.sass";

export interface PaginationProps extends React.HTMLAttributes<HTMLDivElement> {
  total: number;
  limit: number;
  currentPage: number;
  onPageChange: (event: unknown, newPage: number) => void;
}

export const Pagination = React.forwardRef<HTMLDivElement, PaginationProps>(
  ({ className, total, limit, currentPage, onPageChange, ...props }, ref) => {
    const combinedClassName = classNames(styles.pagination, className);
    const pages: string[] = getPages(total, limit, currentPage + 1);

    const isLastPage = (): boolean => {
      if (!pages.length || currentPage + 1 === +pages[pages.length - 1])
        return true;
      return false;
    };

    const isActivePage = (page: number): boolean => currentPage + 1 === page;

    return (
      <div ref={ref} className={combinedClassName} {...props}>
        Найдено: {new Intl.NumberFormat("ru").format(total)}{" "}
        <div className={styles.pages}>
          <Button
            onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
              onPageChange(event, currentPage - 1)
            }
            disabled={currentPage === 0}
            variant="upload"
            className={`${styles.arrow} ${styles.pageItem}`}
          >
            <img
              alt=""
              src={
                currentPage === 0
                  ? "/pagination-arrow-disabled.svg"
                  : "/pagination-arrow.svg"
              }
              className={currentPage === 0 ? "" : styles.reversed}
            />
          </Button>
          {pages.map((page, id) => (
            <span
              key={id}
              className={`${styles.pageItem} ${
                isActivePage(+page)
                  ? styles.active
                  : page !== "..."
                  ? styles.clickable
                  : ""
              }`}
              onClick={(
                event: React.MouseEvent<HTMLButtonElement, MouseEvent>
              ) =>
                !isActivePage(+page) &&
                page !== "..." &&
                onPageChange(event, +page - 1)
              }
            >
              {page}
            </span>
          ))}
          <Button
            disabled={isLastPage()}
            onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
              onPageChange(event, currentPage + 1)
            }
            variant="upload"
            className={`${styles.arrow} ${styles.pageItem}`}
          >
            <img
              alt=""
              src={
                isLastPage()
                  ? "/pagination-arrow-disabled.svg"
                  : "/pagination-arrow.svg"
              }
              className={isLastPage() ? styles.reversed : ""}
            />
          </Button>
        </div>
      </div>
    );
  }
);
