import { HeadCell } from "./types";

export const headCells: readonly HeadCell[] = [
  {
    id: "source_id",
    disablePadding: false,
    label: "ID источника",
  },
  {
    id: "search_type",
    disablePadding: false,
    label: "Тип источника",
  },
  {
    id: "source",
    disablePadding: false,
    label: "Название ресурса",
  },
  {
    id: "additional",
    disablePadding: false,
    label: "Тип продвижения",
  },
  {
    id: "tariff_type",
    disablePadding: false,
    label: "Тип тарификации",
  },
  {
    id: "price",
    disablePadding: false,
    label: "Тариф",
  },

  {
    id: "use",
    disablePadding: false,
    label: "Использование",
  },
];

export const DIRECTION_OPTIONS = [{ value: "Входящий", name: "Входящий" }];
export const DIRECTION_TARIFF_TYPE = [
  { value: "u", name: "Unlimited (U)" },
  { value: "l", name: "Leads (L)" },
  { value: "p", name: "Publication (P)" },
];

export const AVITO_OPTIONS = [
  { value: "Без продвижений", name: "Без продвижений" },
  { value: "Продвижение", name: "Продвижение" },
];

export const HH_OPTIONS = [
  { value: "Стандарт", name: "Стандарт" },
  { value: "Стандарт +", name: "Стандарт +" },
  { value: "Премиум", name: "Премиум" },
  { value: "Без продвижений", name: "Без продвижений" },
];

export const RABOTA_OPTIONS = [
  { value: "ra", name: "активный без продвижений" },
  { value: "rv", name: "входящий без продвижений" },
];

export const ZARPLATA_OPTIONS = [
  { value: "za", name: "активный без продвижений (холодный поиск)" },
  { value: "zv", name: "входящий без продвижений (тёплый поиск)" },
  { value: "zvc", name: "входящий Продвижений (тёплый поиск)" },
];

export const SUPERJOB_OPTIONS = [
  { value: "sa", name: "активный без продвижений" },
  { value: "sv", name: "входящий без продвижений" },
];

export const SOURCE_OPTIONS_SETTING = [
  {
    name: "Head Hunter",
    value: "hh.ru",
  },
  { name: "Авито", value: "Avito" },
  { name: "Rabota.ru", value: "rabota" },
  { name: "зарплата.ру", value: "zarplata" },
  { name: "superjob", value: "superjob" },
];
