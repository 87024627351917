import { createTheme, ThemeOptions } from "@mui/material/styles";

const makeTheme = () => ({
  unit: 8,
  colors: {
    main: {
      accent: "#3374F7",
      superDark: "#262732",
      dark: "#777B89",
      light: "#B4B3B3",
      superLight: "#F3F3F3",
      menu: "#0C3953",
    },
    button: {
      primary: "#217EC6",
      hover: "#71B7ED",
      pressed: "#1C6CAA",
      disable: "#B8D1E5",
      errorLight: "#F8D6D4",
      error: "#D93025",
      errorHover: "#F17068",
      errorPressed: "#C22F25",
    },
    text: {
      accent: "#3374F7",
      superDark: "#0C3953",
      dark: "#777B89",
      light: "#B4B3B3",
      superLight: "#F3F3F3",
      white: "#FFFFFF",
      error: "#EF493E",
      menuInactive: "#B0C4DE",
      tableHeader: "#0C3953",
    },
    status: {
      active: "#53B95D",
      paused: "#ff9800",
      stopped: "#EF493E",
    },
    background: {
      blue: "#E6EEFE",
      blueLight: "#F4F8FF",
      greenLight: "#eef8ef",
      redLight: "#feeeec",
      white: "#FFFFFF",
      main: "#F6F8FA",
    },
  },
  forms: {
    borderRadius: 10,
  },
});

const materialTheme: ThemeOptions = {
  typography: { fontFamily: "Montserrat", fontWeightBold: 600 },
};

export type TTheme = ReturnType<typeof makeTheme> & ThemeOptions;
export default function getTheme() {
  const theme = makeTheme();
  return createTheme({
    ...theme,
    ...materialTheme,
  });
}
