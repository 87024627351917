import { getOffsetDate, handleDate } from "common/utils/handleDate";
import { FilterValues } from "components/Filters";
import { optionType } from "components/UI/Select";
import { CandidatesData } from "services/store/reducers/issued/types";
import { MediaPlanUpload, PlanLine } from "services/store/reducers/media/types";
import { HvAvData } from "services/store/reducers/ws-data/types";

export interface Route {
  name: string;
  value: string;
}
export const MEDIA_PLAN_ROUTE = "/mediaplan";
export const MEDIA_PLAN_CLIENTS_ROUTE = "/mediaplan-clients";
export const MEDIA_PLANS_ROUTE = "/mediaplans";
export const ISSUED_ROUTE = "/issued";
export const WORK_SITE_DATA_ROUTE = "/wsdata";
export const SELECTION_PLAN_ROUTE = "/selection";
export const SETTINGS_ROUTE = "/settings";
export const TAB_ROUTES: Route[] = [
  { name: "Медиапланы", value: MEDIA_PLANS_ROUTE },
  { name: "Оформленные", value: ISSUED_ROUTE },
  { name: "Данные работных сайтов", value: WORK_SITE_DATA_ROUTE },
  { name: "Планы подбора", value: SELECTION_PLAN_ROUTE },
];
export const SIDEBAR_ROUTES: Route[] = [
  { name: "Медиапланы", value: MEDIA_PLANS_ROUTE },
  {
    name: "Настройки",
    value: SETTINGS_ROUTE,
  },
];

export const BREADCRUMBS = [
  {
    text: "Медиапланы",
    link: "mediaplans",
  },
  {
    text: "Планы подбора",
    link: "selection",
  },
];

export const FILE_TYPE = "text/plain;charset=utf8;";

export const EXCEL_ACCEPT_FORMAT =
  "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

export const SUCCESS_FILE_UPLOAD = "Файл успешно загружен";
export const ERROR_FILE_UPLOAD = "Файл содержит ошибки";

export const CLIENT_NAME_DIRECTORY = [
  { id: 42, name: "ГСП" },
  { id: 43, name: "Билайн - аналитика" },
  { id: 45, name: "Работут" },
  { id: 46, name: "ГСП-2" },
  { id: 47, name: "Demo" },
  { id: 48, name: "TestCases" },
  { id: 49, name: "Test_c" },
  { id: 50, name: "Auchan" },
  { id: 51, name: "Megafon" },
  { id: 52, name: "X5 RF" },
  { id: 53, name: "Lamoda" },
  { id: 54, name: "Самокат" },
  { id: 55, name: "Lenta" },
  { id: 56, name: "Ozon" },
  { id: 57, name: "X5 ВПРОК" },
  { id: 58, name: "Работут ДВ" },
  { id: 59, name: "ВТБ" },
  { id: 60, name: "Работут ДРС" },
  { id: 61, name: "Работут ДПСЛ" },
  { id: 62, name: "Test_a" },
  { id: 63, name: "СДЭК" },
  { id: 64, name: "Ancor" },
  { id: 65, name: "МТС" },
];

export const SOURCE_OPTIONS = [
  { name: "hh.ru входящий", value: "hv" },
  { name: "hh.ru исходящий", value: "ha" },
  { name: "av.ru входящий", value: "av" },
  { name: "av.ru исходящий", value: "aa" },
  { name: "Rabota.ru активный без продвижений", value: "ra" },
  { name: "Rabota.ru входящий без продвижений", value: "rv" },
  {
    name: "зарплата.ру активный без продвижений (холодный поиск)",
    value: "za",
  },
  { name: "зарплата.ру входящий без продвижений (тёплый поиск)", value: "zv" },
  {
    name: "зарплата.ру входящий Продвижений (тёплый поиск)",
    value: "zvc",
  },
  { name: "superjob активный без продвижений", value: "sa" },
  { name: "superjob входящий без продвижений", value: "sv" },
];

interface MediaPlanTable extends Omit<MediaPlanUpload, "id" | "client_id"> {}
interface PlanUploadTable
  extends Omit<PlanLine, "id" | "date" | "hire" | "closed"> {}
interface HvAvDataTable
  extends Pick<HvAvData, "id" | "uploaded_at" | "profile" | "region" | "days"> {
  manage: string;
}
interface CandidatesDataTable
  extends Omit<
    CandidatesData,
    "candidate_id" | "real_location" | "upload" | "real_source" | "client_id"
  > {}

type MediaPlanColumnId = keyof MediaPlanTable;
type PlanUploadColumnId = keyof PlanUploadTable;
type HvAvDataColumnId = keyof HvAvDataTable;
type CandidatesDataColumnId = keyof CandidatesDataTable;
export interface MediaPlanColumn {
  id: MediaPlanColumnId;
  label: string;
}
export interface PlanUploadColumn {
  id: PlanUploadColumnId;
  label: string;
}
export interface HvAvDataColumn {
  id: HvAvDataColumnId;
  label: string;
}
export interface CandidatesDataColumn {
  id: CandidatesDataColumnId;
  label: string;
}
export type Caption = {
  unions: number;
  label: string;
};
export const MEDIA_PLAN_CAPTIONS: Caption[] = [
  { unions: 6, label: "" },
  { unions: 5, label: "План на неделю" },
  { unions: 7, label: "План на месяц" },
  { unions: 5, label: "Период" },
];
export const MEDIA_PLAN_COLUMNS: readonly MediaPlanColumn[] = [
  { id: "region", label: "Регион" },
  { id: "city", label: "Населенный пункт" },
  { id: "profile", label: "Профиль" },
  { id: "source", label: "Источник" },
  { id: "source_type", label: "Полное наименование источника" },
  { id: "month_plan", label: "Потребность (план в месяц)" },
  { id: "priority", label: "Приоритет размещения" },
  { id: "publications_for_week", label: "Кол-во размещений в неделю" },
  { id: "leads_for_week", label: "Лидов в неделю" },
  { id: "price_for_lead", label: "Стоимость лида в неделю" },
  { id: "amount_on_start", label: "Бюджет на неделю" },
  { id: "publications_for_month", label: "Кол-во размещений в месяц" },
  { id: "leads_for_month", label: "Лидов в месяц" },
  { id: "price_for_lead_month", label: "Стоимость лида в месяц" },
  { id: "future_registered", label: "Прогноз оформленных в месяц" },
  { id: "hire_chance", label: "Вероятность найма" },
  { id: "registered_price", label: "Стоимость оформленного" },
  { id: "month_amount", label: "Бюджет на месяц" },
  { id: "date_start", label: "Начало периода" },
  { id: "date_end", label: "Конец периода" },
  {
    id: "avito_week_contacts_requests",
    label: "Авито-запросы контактов в неделю",
  },
  { id: "avito_week_callbacks", label: "Авито-вызовы в неделю" },
  { id: "promotion_type", label: "Тип продвижения" },
];
export const PLAN_UPLOAD_COLUMNS: readonly PlanUploadColumn[] = [
  { id: "hire_count", label: "К подбору" },
  { id: "real_profile", label: "Профиль" },
  { id: "real_location", label: "Населенный пункт" },
  { id: "real_region", label: "Регион" },
  { id: "real_macroregion", label: "Макрорегион" },
];
export const HV_AV_DATA_COLUMNS: readonly HvAvDataColumn[] = [
  { id: "id", label: "№" },
  { id: "uploaded_at", label: "Дата загрузки" },
  { id: "profile", label: "Профиль" },
  { id: "region", label: "Регион" },
  { id: "days", label: "Дни" },
  { id: "manage", label: "Управление" },
];
export const CANDIDATES_DATA_COLUMNS: readonly CandidatesDataColumn[] = [
  { id: "id", label: "№" },
  { id: "type", label: "Тип" },
  { id: "source", label: "Источник" },
  { id: "shop", label: "Регион" },
  { id: "position", label: "Вакансия" },
  { id: "status", label: "Статус" },
  { id: "date", label: "Дата" },
  { id: "real_profile", label: "Профиль" },
];

export const STORE_INIT_FILE_NAME = "Файл не выбран";
export const DOWNLOAD_FILE = "#";
export const EMPTY_DATA = "Нет данных";

export const API_URL = "https://api.hrrocket.ru/api";
export const TEMPLATE_CANDIDATES_URL =
  "https://api.hrrocket.ru/static/base-candidates.xlsx";
export const TEMPLATE_PLAN_UPLOAD_URL =
  "https://api.hrrocket.ru/static/base-plan.xlsx";

export const initState = {
  loading: false,
  error: null,
  data: null,
};

export const FILTERS_INFO = {
  delimiter: 'Используйте ";" в качестве разделителя',
};

type FilterType = "slider" | "select" | "date" | "input" | "inputNumber";

export interface FilterConfiguration {
  labels: { [key in keyof FilterValues]: string };
  types: { [key in keyof FilterValues]: FilterType };
  defaults: FilterValues;
  errorsPhrases: { [key in keyof FilterValues]: string };
  steps?: { [key in keyof FilterValues]: number };
  mins?: { [key in keyof FilterValues]: number };
  maxs?: { [key in keyof FilterValues]: number };
  selectOptions?: { [key in keyof FilterValues]: optionType[] };
  groups?: { [key: number]: string[] };
}

export const MEDIAPLAN_FILTERS: FilterConfiguration = {
  labels: {
    hire_chance: "Вероятность найма",
    price_for_lead: "Стоимость лида",
    amount_on_start: "Бюджет",
    registered_price: "Стоимость оформленного",
  },
  types: {
    hire_chance: "slider",
    price_for_lead: "slider",
    amount_on_start: "slider",
    registered_price: "slider",
  },
  defaults: {
    hire_chance: 0.01,
    price_for_lead: 10_000,
    amount_on_start: 100_000_000,
    registered_price: 1_000_000,
  },
  steps: {
    hire_chance: 0.01,
    price_for_lead: 1,
    amount_on_start: 1_000,
    registered_price: 1,
  },
  mins: {
    hire_chance: 0.001,
    price_for_lead: 1,
    amount_on_start: 1_000,
    registered_price: 1,
  },
  maxs: {
    hire_chance: 1,
    price_for_lead: 10_000,
    amount_on_start: 100_000_000,
    registered_price: 1_000_000,
  },
  errorsPhrases: {
    hire_chance: "вероятности найма",
    price_for_lead: "стоимости лида",
    amount_on_start: "бюджета",
    registered_price: "стоимости оформленного",
  },
};

export const HV_AV_FILTERS: FilterConfiguration = {
  labels: {
    source_type: "Источник",
    date_from: "Дата отчета от",
    date_to: "Дата отчета до",
    uploaded_from: "Дата загрузки от",
    uploaded_to: "Дата загрузки до",
    profile: "Профиль",
    region: "Населенный пункт",
  },
  types: {
    source_type: "select",
    date_from: "date",
    date_to: "date",
    uploaded_from: "date",
    uploaded_to: "date",
    profile: "input",
    region: "input",
  },
  defaults: {
    source_type: "hv",
    date_from: handleDate(getOffsetDate(-14)),
    date_to: handleDate(getOffsetDate(0)),
  },
  errorsPhrases: {
    source_type: "Выбран несуществующий источник",
  },
  selectOptions: { source_type: SOURCE_OPTIONS },
  groups: {
    1: ["source_type"],
    2: ["date_from", "date_to"],
    3: ["uploaded_from", "uploaded_to"],
    4: ["profile", "region"],
  },
};

export const CANDIDATES_FILTERS: FilterConfiguration = {
  labels: {
    profile: "Профиль",
    region: "Населённый пункт",
    uploaded_from: "Дата загрузки от",
    uploaded_to: "Дата загрузки до",
  },
  types: {
    profile: "input",
    region: "input",
    uploaded_from: "date",
    uploaded_to: "date",
  },
  defaults: {
    uploaded_from: handleDate(getOffsetDate(-14)),
    uploaded_to: handleDate(getOffsetDate(0)),
  },
  errorsPhrases: {},
  groups: {
    1: ["profile", "region"],
    2: ["uploaded_from", "uploaded_to"],
  },
};

export const MEDIAPLANS_FILTERS: FilterConfiguration = {
  labels: {
    id: "Номер плана подбора",
    uploaded_from: "Дата загрузки от",
    uploaded_to: "Дата загрузки до",
  },
  types: {
    id: "inputNumber",
    uploaded_from: "date",
    uploaded_to: "date",
  },
  defaults: {},
  errorsPhrases: {
    id: "Значение должно быть целым положительным числом",
  },
  groups: {
    1: ["id"],
    2: ["uploaded_from", "uploaded_to"],
  },
};

export const RECRUITMENT_FILTERS: FilterConfiguration = {
  labels: {
    date_from: "Дата плана от",
    date_to: "Дата плана до",
    profile: "Профиль",
    region: "Населённый пункт",
  },
  types: {
    date_from: "date",
    date_to: "date",
    profile: "input",
    region: "input",
  },
  defaults: {},
  errorsPhrases: {},
  groups: {
    1: ["date_from", "date_to"],
    2: ["profile", "region"],
  },
};

export const RECRUITMENTS_FILTERS: FilterConfiguration = {
  labels: {
    id: "Номер плана подбора",
    uploaded_from: "Дата загрузки от",
    uploaded_to: "Дата загрузки до",
  },
  types: {
    id: "inputNumber",
    uploaded_from: "date",
    uploaded_to: "date",
  },
  defaults: {},
  errorsPhrases: {
    id: "Значение должно быть целым положительным числом",
  },
  groups: {
    1: ["id"],
    2: ["uploaded_from", "uploaded_to"],
  },
};
