import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

import { convertToOptions } from "common/utils/convertToOptions";
import { Button } from "components/UI/Button";
import { Input } from "components/UI/Input";
import BasicSelect from "components/UI/Select";
import {
  selectMediaPlanCities,
  selectMediaPlanRegions,
  selectMediaPlanSources,
  selectMediaPlanSourceTypes,
} from "services/store/selectors";
import { useAppSelector } from "services/store/store";

import styles from "./styles.module.sass";

interface AdditionalFiltersProps extends React.HTMLAttributes<HTMLDivElement> {
  handleUpdate: (filters?: AdditionalFilters) => void;
  defaultFilterValues?: AdditionalFilters;
}

export type AdditionalFilters = {
  profile: string | null;
  city: string | null;
  region: string | null;
  source: string | null;
  source_type: string | null;
};

const emptyFilters: AdditionalFilters = {
  city: null,
  profile: null,
  region: null,
  source: null,
  source_type: null,
};

const AdditionalFiltersPanel: React.FC<AdditionalFiltersProps> = ({
  handleUpdate,
  defaultFilterValues = emptyFilters,
  className,
  children,
  ...props
}) => {
  const { handleSubmit, watch, control, reset, resetField } =
    useForm<AdditionalFilters>({
      mode: "onChange",
      defaultValues: defaultFilterValues,
    });

  const citiesList = useAppSelector(selectMediaPlanCities).sort();
  const regionsList = useAppSelector(selectMediaPlanRegions).sort();
  const sourcesList = useAppSelector(selectMediaPlanSources);
  const sourceTypesList = useAppSelector(selectMediaPlanSourceTypes);

  useEffect(() => {
    const subscription = watch(() => handleSubmit(handleUpdate)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, handleUpdate, watch]);

  return (
    <div className={`${styles.additionalFilters} ${className}`} {...props}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Дополнительные фильтры
        </AccordionSummary>
        <AccordionDetails className={styles.details}>
          <Controller
            name="profile"
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <Input
                placeholder="Профиль"
                onChange={onChange}
                value={value ?? ""}
                endAdornment={
                  value && (
                    <button
                      className={styles.onClickWrapper}
                      onClick={() => resetField(name)}
                    >
                      <CloseIcon color="action" className={styles.emptyInput} />
                    </button>
                  )
                }
              />
            )}
          />
          <Controller
            name="city"
            control={control}
            render={({ field: { onChange, value } }) => (
              <BasicSelect
                variant="standard"
                select={value ?? defaultFilterValues?.city ?? ""}
                setSelect={onChange}
                options={convertToOptions(citiesList)}
                label="Населённый пункт"
                isFullWidth
              />
            )}
          />
          <Controller
            name="region"
            control={control}
            render={({ field: { onChange, value } }) => (
              <BasicSelect
                variant="standard"
                select={value ?? defaultFilterValues?.region ?? ""}
                setSelect={onChange}
                options={convertToOptions(regionsList)}
                label="Регион"
                isFullWidth
              />
            )}
          />
          <Controller
            name="source"
            control={control}
            render={({ field: { onChange, value } }) => (
              <BasicSelect
                variant="standard"
                select={value ?? defaultFilterValues?.source ?? ""}
                setSelect={onChange}
                options={convertToOptions(sourcesList)}
                label="Источник"
                isFullWidth
              />
            )}
          />
          <Controller
            name="source_type"
            control={control}
            render={({ field: { onChange, value } }) => (
              <BasicSelect
                variant="standard"
                select={value ?? defaultFilterValues?.source_type ?? ""}
                setSelect={onChange}
                options={convertToOptions(sourceTypesList)}
                label="Тип размещения"
                isFullWidth
              />
            )}
          />
          <Button onClick={() => reset(emptyFilters)}>Сброс фильтров</Button>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default AdditionalFiltersPanel;
