export const handleParams = <T extends object>(
  params: T,
  excludes: string[] = [],
  add: boolean = false,
): string => {
  let result = Object.keys(params)
    .filter((key) => !excludes.includes(key))
    .map((key) => `${key}=${params[key]}`)
    .join("&");
  if (result.length > 0) result = `${add ? "&" : "?"}${result}`;
  return result;
};
