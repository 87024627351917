import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { uploadFile } from "api/Rocket";
import { AxiosResponse } from "axios";
import {
  CANDIDATES_DATA_COLUMNS,
  CANDIDATES_FILTERS,
  EXCEL_ACCEPT_FORMAT,
  SUCCESS_FILE_UPLOAD,
  TEMPLATE_CANDIDATES_URL,
} from "common/const";
import useNotify from "common/hooks/useNotify";
import { useUploadHandler } from "common/hooks/useUploadHandler";
import { UploadMessage, UploadResponse } from "common/interfaces";
import { addOptionalParams } from "common/utils/addOptionalParams";
import hasFullAccess from "common/utils/checkRole";
import { Filters, FilterValues } from "components/Filters";
import CandidatesDataTable from "components/tables/IssuedTable";
import { H1 } from "components/typography";
import { Button } from "components/UI/Button";
import HorizontalLine from "components/UI/HorizontalLine";
import LastUploadDate from "components/UI/LastUploadDate";
import { Message } from "components/UI/Message";
import Upload from "components/UI/Upload";
import { fetchCandidatesData } from "services/store/reducers/issued";
import { FetchCandidatesDataParams } from "services/store/reducers/issued/types";
import { useAppDispatch, useAppSelector } from "services/store/store";
import useSessionStorageState from "use-session-storage-state";

import commonStyles from "../common-styles.module.sass";
import styles from "./styles.module.sass";

const IssuedPage: React.FC = (): JSX.Element => {
  const { candidatesData } = useAppSelector((state) => state.issued);
  const { uploaderName, clientId, role } = useAppSelector(
    (state) => state.globalParams,
  );
  const [uploadMessage, setUploadMessage] =
    useSessionStorageState<UploadMessage | null>("upload-message-issued");
  const [isSaving, setSaving] = useState<boolean>(false);
  const { fileName, file, handleUpload } = useUploadHandler();
  const { ToastContainer, notifyUploading } = useNotify();
  const dispatch = useAppDispatch();
  const downloadRef = useRef<HTMLAnchorElement>(null);
  const uploadRef = useRef<HTMLInputElement>(null);

  const handleDownloadClick = (): void => {
    if (downloadRef.current) downloadRef.current.click();
  };

  const handleUploadClick = (): void => {
    if (uploadRef.current) uploadRef.current.click();
  };

  const handleSaveClick = (): void => {
    if (file && !isSaving) {
      setSaving(true);
      setUploadMessage(null);
      const formData: FormData = new FormData();
      const params: URLSearchParams = new URLSearchParams({
        client_id: clientId.toString(),
        uploader_name: uploaderName,
      });
      const endpoint: string = `/candidates/upload-candidates?${params.toString()}`;

      formData.append("file", file);

      uploadFile<UploadResponse>(endpoint, formData)
        .then((response: AxiosResponse<UploadResponse>) => {
          if (
            "error" in response.data.response &&
            response.data.response.error
          ) {
            notifyUploading(fileName, "error");
            setUploadMessage({
              message: `${fileName} : ${response.data.response.error}`,
              success: false,
            });
          } else {
            notifyUploading(fileName, "success");
            setUploadMessage({
              message: `${fileName} : ${SUCCESS_FILE_UPLOAD}`,
              success: true,
            });
          }
        })
        .catch((error: Error) => {
          setUploadMessage({
            message: `${fileName} : ${error.message}`,
            success: false,
          });
        })
        .finally(() => {
          setSaving(false);
        });
    }
  };

  const handleUpdateFilters = (filterValues: FilterValues): void => {
    const sendParams: FetchCandidatesDataParams = {
      client_id: clientId,
    };
    addOptionalParams(sendParams, filterValues);
    dispatch(fetchCandidatesData(sendParams));
  };

  useEffect(() => {
    dispatch(
      fetchCandidatesData({
        client_id: clientId,
        uploaded_from: CANDIDATES_FILTERS.defaults.uploaded_from.toString(),
        uploaded_to: CANDIDATES_FILTERS.defaults.uploaded_to.toString(),
      }),
    );
  }, [dispatch, clientId]);

  return (
    <>
      <section className={commonStyles.heading}>
        <H1 color="#0C3953">Оформленные</H1>
        <Filters
          onUpdateFilters={handleUpdateFilters}
          configuration={CANDIDATES_FILTERS}
        />
      </section>
      {hasFullAccess(role) && (
        <>
          <section>
            <Link
              ref={downloadRef}
              to={TEMPLATE_CANDIDATES_URL}
              style={{ display: "none" }}
            />
            <Button
              style={{ margin: "20px 0 16px" }}
              variant="download"
              onClick={handleDownloadClick}
            >
              Скачать шаблон XLSX
            </Button>
          </section>
          <HorizontalLine />
        </>
      )}
      {hasFullAccess(role) && (
        <>
          <section className={styles.confirmation}>
            <LastUploadDate date="7/10/2023" style={{ gridColumn: "1 / 3" }} />
            <Upload
              forwardRef={uploadRef}
              id="upload"
              onUpload={handleUpload}
              accept={EXCEL_ACCEPT_FORMAT}
              label={fileName}
            >
              <Button
                variant="upload"
                loading={isSaving}
                disabled={isSaving}
                onClick={handleUploadClick}
              >
                Выберите файл
              </Button>
            </Upload>
            <Button
              variant="primary"
              onClick={handleSaveClick}
              style={{ placeSelf: "end" }}
              loading={isSaving}
              disabled={isSaving}
            >
              Сохранить
            </Button>
            <ToastContainer />
          </section>
          {uploadMessage && (
            <Message
              text={uploadMessage.message}
              success={uploadMessage.success}
            />
          )}
        </>
      )}
      <section>
        <CandidatesDataTable
          response={candidatesData}
          columns={CANDIDATES_DATA_COLUMNS}
        />
      </section>
    </>
  );
};

export default IssuedPage;
