import { FilterConfiguration } from "common/const";
import { FilterValues } from "components/Filters";
import { SafeParseReturnType, z as zod } from "zod";

export function convertToNumbers(values: FilterValues): FilterValues {
  const result: FilterValues = {};
  Object.keys(values).forEach((key) => {
    if (typeof values[key] === "number") result[key] = values[key];
  });
  return result;
}

export function checkByLimits(
  fieldValues: FilterValues,
  configuration: FilterConfiguration,
): SafeParseReturnType<number, number>[] {
  const { mins, maxs, errorsPhrases } = configuration;
  if (mins && maxs)
    return Object.keys(fieldValues).map((key) => {
      return zod
        .number()
        .min(
          mins[key],
          `Значение ${errorsPhrases[key]} должно быть больше или равно ${mins[key]}`,
        )
        .max(
          maxs[key],
          `Значение ${errorsPhrases[key]} должно быть меньше или равно ${maxs[key]}`,
        )
        .safeParse(fieldValues[key]);
    });
  return [];
}

export function checkOnPositiveInteger(
  value: string | number | undefined,
  errorPhrase: string,
): SafeParseReturnType<number, number> | undefined {
  if (value !== undefined && value !== "") {
    return zod
      .number()
      .min(1, errorPhrase)
      .int(errorPhrase)
      .safeParse(Number(value));
  }
}
