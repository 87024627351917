import { H1, MiddleText } from "components/typography";
import { ToDoList } from "components/ToDo";

import styles from "./styles.module.sass";

const MainPage: React.FC = (): JSX.Element => {
  return (
    <>
      <section className={styles.intro}>
        <H1 color="#0C3953">Добро пожаловать</H1>
        <MiddleText color="#0C3953">
          Чтобы начать работу, загрузите файлы для формирования медиаплана
        </MiddleText>
      </section>
      <section>
        <ToDoList />
      </section>
    </>
  );
};

export default MainPage;
