import styled from '@emotion/styled';
import React from 'react';

import {TTextProps} from './types';

// figma - T1
export default styled(({className, ...props}: TTextProps) => {
    return <div {...props} className={`BaseText ${className}`} />;
})`
    font-size: 14px;
    color: ${({color, theme}) => color ?? theme.colors.text.superDark};
    display: ${({display}) => display ?? 'block'};
`;
