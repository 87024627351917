import { Route, Routes } from "react-router";

import {
  MainPage,
  MediaPlanClients,
  MediaPlanPage,
  NotFoundPage,
  SettingsPage,
} from "pages";
import Tabs from "pages/tabs";

import {
  ISSUED_ROUTE,
  MEDIA_PLAN_CLIENTS_ROUTE,
  MEDIA_PLAN_ROUTE,
  MEDIA_PLANS_ROUTE,
  SELECTION_PLAN_ROUTE,
  SETTINGS_ROUTE,
  WORK_SITE_DATA_ROUTE,
} from "../common/const";
import Layout from "./layout";

const AppRouter: React.FC = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<MainPage />} />
        <Route path={MEDIA_PLANS_ROUTE} element={<Tabs />} />
        <Route path={MEDIA_PLAN_ROUTE} element={<MediaPlanPage />} />
        <Route path={MEDIA_PLAN_CLIENTS_ROUTE} element={<MediaPlanClients />} />
        <Route path={ISSUED_ROUTE} element={<Tabs />} />
        <Route path={WORK_SITE_DATA_ROUTE} element={<Tabs />} />
        <Route path={SELECTION_PLAN_ROUTE} element={<Tabs />} />
        <Route path={SETTINGS_ROUTE} element={<SettingsPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};

export default AppRouter;
