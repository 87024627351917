
import _ from "lodash";

export function groupByListId<T>(data: T[], key: keyof T): { [key: string]: T } {
    const groupedData = _.groupBy(data, key);
    const result: { [key: string]: T } = {};

    for (const id in groupedData) {
        if (groupedData.hasOwnProperty(id)) {
            result[String(id)] = groupedData[id][0];
        }
    }
    return result;
}
