import { RatesErrors } from "./types";

export function isValidSourceId(sourceId: string): boolean {
  if (
    sourceId !== "" &&
    Number(sourceId) >= 0 &&
    Number(sourceId) <= 100 &&
    Number(sourceId) % 1 === 0
  )
    return true;
  return false;
}

export function hasError(
  errors: RatesErrors,
  id: number,
  field: string
): boolean {
  if (errors[id] && field in errors[id] && errors[id][field]) return true;
  return false;
}
