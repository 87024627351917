import React from "react";

import Slider from "@mui/material/Slider";

import classNames from "classnames";
import { FilterConfiguration } from "common/const";
import { FloatingLabelInput } from "components/UI/FloatingLabelInput";

import styles from "./styles.module.sass";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  value: number | string;
  onFilterChange: (newValue: number | number[], filter: string) => void;
  id: string;
  configuration: FilterConfiguration;
  label: string;
  isErrored?: boolean;
}

export const InputSlider = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      className,
      value,
      onFilterChange,
      id,
      configuration,
      label,
      isErrored = false,
      ...props
    },
    ref,
  ) => {
    const combinedClassName = classNames(styles.slider, className);
    return (
      <div ref={ref} className={combinedClassName} {...props}>
        <Slider
          aria-label="Default"
          valueLabelDisplay="auto"
          value={Number(value)}
          onChange={(_, newValue) => onFilterChange(newValue, id)}
          step={configuration.steps?.[id]}
          min={configuration.mins?.[id]}
          max={configuration.maxs?.[id]}
        />
        <FloatingLabelInput
          value={value.toString()}
          min={configuration.mins?.[id]}
          max={configuration.maxs?.[id]}
          onInputChange={(_, newValue) => onFilterChange(Number(newValue), id)}
          type="number"
          label={label}
          isError={isErrored}
          required
        />
      </div>
    );
  },
);
