import styled from "@emotion/styled";
import React, { RefObject } from "react";

import styles from "./styles.module.sass";

const Input = styled.input`
  display: none;
`;

export type TUpload = React.PropsWithChildren<{
  id: string;
  accept?: string;
  multiple?: boolean;
  disabled?: boolean;
  onUpload: (files: FileList | null) => void;
  forwardRef?: RefObject<HTMLInputElement>;
  label?: string;
}>;

const Upload: React.FC<TUpload> = ({
  id,
  accept = "image/*",
  multiple,
  disabled,
  children,
  onUpload,
  forwardRef,
  label,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onUpload(e.currentTarget.files);
  };

  return (
    <label className={styles.fit} htmlFor={id}>
      <Input
        ref={forwardRef}
        id={id}
        accept={accept}
        multiple={multiple}
        type="file"
        disabled={disabled}
        onChange={handleChange}
      />
      <div className={styles.info}>
        {children}
        {label && <span className={styles.label}>{label}</span>}
      </div>
    </label>
  );
};

export default Upload;
