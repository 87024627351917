import styles from "./styles.module.sass";

export const Separator: React.FC = () => (
  <>
    <img
      className={styles.separator}
      src="/arrow-right-disabled.svg"
      alt="gray disabled right arrow"
    />
  </>
);
