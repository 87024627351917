import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ListAltIcon from "@mui/icons-material/ListAlt";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import classNames from "classnames";
import hasFullAccess from "common/utils/checkRole";
import { handleDate } from "common/utils/handleDate";
import { MUIIconButton } from "components/UI/MUIIconButton";
import { Pagination } from "components/UI/Pagination";
import { getNameById } from "pages/media/helpers";
import { MediaUpload } from "services/store/reducers/media/types";
import { useAppSelector } from "services/store/store";

import commonStyles from "../common-styles.module.sass";
import DownloadButton from "./components/DownloadButton";
import { Column, columns as mockedColumns } from "./mockedData";
import styles from "./styles.module.sass";

interface Data {
  file: JSX.Element;
  date: string;
  status: JSX.Element;
  media: JSX.Element;
  download: JSX.Element;
  filteredMedia: JSX.Element;
}

interface Props {
  data?: MediaUpload[] | null;
}

const columns: Column[] = [];

export default function MediaPlanTable({ data }: Props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(4);

  const { clientId, role } = useAppSelector((state) => state.globalParams);

  const restrictedColumns = ["media", "download"];

  const navigate = useNavigate();

  const rows: Data[] = data
    ? data.map((row: MediaUpload) => {
        return {
          file: <>{row.plan_id}</>,
          date: handleDate(row.uploaded_at, true, true),
          status: <>{row.uploader_name}</>,
          media: <>{row.id}</>,
          download: <DownloadButton filePath={row.path} />,
          filteredMedia: (
            <MUIIconButton
              icon={<ListAltIcon />}
              onClick={() =>
                navigate(
                  `/mediaplan-clients?id=${row.id}&uploader_name=${row.uploader_name}`,
                )
              }
            />
          ),
        };
      })
    : [];

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (clientId) {
      const client = getNameById(clientId);
      columns.forEach((column) => {
        if (column.label.includes("[")) {
          column.label = column.label.replace("[Имя клиента]", client);
        }
      });
    }
  }, [clientId]);

  useEffect(() => {
    columns.length = 0;
    if (hasFullAccess(role)) {
      columns.push(...mockedColumns);
    } else {
      columns.push(
        ...mockedColumns.filter(
          (column) => !restrictedColumns.includes(column.id),
        ),
      );
    }
  });

  return (
    <>
      <Paper className={commonStyles.table}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column, id) => (
                  <TableCell className={commonStyles.tableHead} key={id}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, rowId) => {
                  return (
                    <TableRow role="checkbox" tabIndex={-1} key={rowId} hover>
                      {columns.map((column, columnId) => {
                        const value = row[column.id];
                        const tableCellClasses = classNames(
                          commonStyles.dataCell,
                          (column.id === "file" || column.id === "media") &&
                            styles.clickable,
                          (column.id === "download" ||
                            column.id === "filteredMedia") &&
                            styles.centered,
                        );
                        return (
                          <TableCell
                            className={tableCellClasses}
                            onClick={(): void => {
                              data &&
                                (column.id === "media"
                                  ? navigate(
                                      `/mediaplan?plan=media&id=${Number(data[rowId + page * rowsPerPage].id)}&uploader_name=${data[rowId + page * rowsPerPage].uploader_name}`,
                                    )
                                  : column.id === "file" &&
                                    navigate(
                                      `/mediaplan?plan=selection&id=${Number(data[rowId + page * rowsPerPage].plan_id)}`,
                                    ));
                            }}
                            key={column.id}
                          >
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Pagination
        total={rows.length}
        limit={rowsPerPage}
        currentPage={page}
        onPageChange={handleChangePage}
        className={commonStyles.pagination}
      />
    </>
  );
}
