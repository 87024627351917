import { useEffect, useState } from "react";

import { EMPTY_DATA, RECRUITMENTS_FILTERS } from "common/const";
import { addOptionalParams } from "common/utils/addOptionalParams";
import { checkOnPositiveInteger } from "common/utils/zodValidators";
import { Filters, FilterValues } from "components/Filters";
import PlanUploadsTable from "components/tables/PlanUploadsTable";
import { H1 } from "components/typography";
import { Loader } from "components/UI/Loader";
import { fetchPlanUploads } from "services/store/reducers/media";
import { FetchPlanUploadsParams } from "services/store/reducers/media/types";
import { useAppDispatch, useAppSelector } from "services/store/store";
import { SafeParseReturnType } from "zod";

import commonStyles from "../common-styles.module.sass";

const SelectionPage: React.FC = (): JSX.Element => {
  const { clientId } = useAppSelector((state) => state.globalParams);
  const { planUploadsLoading, planUploadsData } = useAppSelector(
    (state) => state.mediaplan,
  );

  const dispatch = useAppDispatch();

  const [errors, setErrors] = useState<string[]>();

  const handleUpdateFilters = (filterValues: FilterValues): void => {
    const sendParams: FetchPlanUploadsParams = {
      client_id: clientId,
    };
    addOptionalParams(sendParams, filterValues);

    let result: SafeParseReturnType<number, number> | undefined;
    result = checkOnPositiveInteger(
      filterValues.id,
      RECRUITMENTS_FILTERS.errorsPhrases.id,
    );
    const errorMessage = result?.error?.issues[0].message;

    if (errorMessage) {
      setErrors([errorMessage]);
      return;
    }

    setErrors(undefined);
    dispatch(fetchPlanUploads(sendParams));
  };

  useEffect(() => {
    dispatch(
      fetchPlanUploads({
        client_id: clientId,
      }),
    );
  }, [dispatch, clientId]);

  return (
    <>
      <section className={commonStyles.heading}>
        <H1 color="#0C3953">Планы подбора</H1>
        <Filters
          onUpdateFilters={handleUpdateFilters}
          configuration={RECRUITMENTS_FILTERS}
          errors={errors}
        />
      </section>

      {planUploadsLoading ? (
        <Loader />
      ) : planUploadsData ? (
        <PlanUploadsTable data={planUploadsData} navigable />
      ) : (
        EMPTY_DATA
      )}
    </>
  );
};

export default SelectionPage;
