import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers/DatePicker";

import "dayjs/locale/ru";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { adaptDate } from "./helpers";

interface CustomDatePickerProps extends DatePickerProps<Date> {
  setValue?: (newValue: Date | null) => void;
}

const BasicDatePicker: React.FC<CustomDatePickerProps> = ({
  value,
  setValue,
  ...props
}): JSX.Element => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
      <DatePicker
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderRadius: "8px",
            },
          },
        }}
        value={value}
        onChange={(newValue) => setValue && setValue(adaptDate(newValue))}
        slotProps={{ textField: { size: "small" } }}
        {...props}
      />
    </LocalizationProvider>
  );
};

export default BasicDatePicker;
