import axios, { AxiosResponse } from "axios";
import { API_URL } from "common/const";
import { handleParams } from "common/utils/handleParams";

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  DetailedPlanUpload,
  FetchMediaPlanParams,
  FetchMediaUploadsParams,
  FetchPlanUploadParams,
  FetchPlanUploadsParams,
  MediaPlanResponse,
  MediaUpload,
  PlanUpload,
  SendUltimateUploadParams,
  SendUploadConfigParams,
  StateType,
} from "./types";

const initialState: StateType = {
  mediaUploadsLoading: false,
  mediaUploadsData: null,
  mediaUploadsError: null,
  uploadConfigLoading: false,
  uploadConfigData: null,
  uploadConfigError: null,
  planUploadsLoading: false,
  planUploadsData: null,
  planUploadsError: null,
  planUploadLoading: false,
  planUploadData: null,
  planUploadError: null,
  ultimateUploadLoading: false,
  ultimateUploadData: null,
  ultimateUploadError: null,
  mediaPlanUploadLoading: false,
  mediaPlanUploadData: null,
  mediaPlanFilters: null,
  mediaPlanSum: null,
  mediaPlanUploadError: null,
};

export const fetchMediaPlans = createAsyncThunk(
  "media/fetchMediaPlans",
  async (params: FetchMediaUploadsParams, { rejectWithValue }) => {
    try {
      const urlParams = handleParams(params, ["client_id"]);
      const response: AxiosResponse<MediaUpload[]> = await axios.get(
        `${API_URL}/media-plan/get-all-media-plans/${params.client_id}${urlParams}`,
      );
      const sortedData = response.data.sort((a, b) => b.id - a.id); // TODO: move this to createSelector
      return sortedData;
    } catch (error) {
      return axios.isAxiosError(error) && error.response
        ? rejectWithValue(error.response.data)
        : rejectWithValue(error.message);
    }
  },
);

export const fetchMediaPlan = createAsyncThunk(
  "media/fetchMediaPlan",
  async (params: FetchMediaPlanParams, { rejectWithValue }) => {
    try {
      const urlParams = handleParams(params, ["upload"]);
      const response: AxiosResponse<MediaPlanResponse> = await axios.get(
        `${API_URL}/media-plan/get-clients-media-plan/${params.upload}${urlParams}`,
      );
      return response.data;
    } catch (error) {
      return axios.isAxiosError(error) && error.response
        ? rejectWithValue(error.response.data)
        : rejectWithValue(error.message);
    }
  },
);

export const fetchPlanUpload = createAsyncThunk(
  "media/fetchPlanUpload",
  async (params: FetchPlanUploadParams, { rejectWithValue }) => {
    try {
      const urlParams = handleParams(params, ["plan_id"]);
      const response: AxiosResponse<DetailedPlanUpload> = await axios.get(
        `${API_URL}/recruitment-plan/get-recruitment-plan/${params.plan_id}${urlParams}`,
      );
      const planUpload: DetailedPlanUpload = response.data;
      return planUpload;
    } catch (error) {
      return axios.isAxiosError(error) && error.response
        ? rejectWithValue(error.response.data)
        : rejectWithValue(error.message);
    }
  },
);

export const fetchPlanUploads = createAsyncThunk(
  "media/fetchPlanUploads",
  async (params: FetchPlanUploadsParams, { rejectWithValue }) => {
    try {
      const urlParams = handleParams(params, ["client_id"]);
      const response: AxiosResponse<PlanUpload[]> = await axios.get(
        `${API_URL}/recruitment-plan/get-all-recruitment-plans/${params.client_id}${urlParams}`,
      );
      const sortedData = response.data.sort((a, b) => b.id - a.id); // TODO: move this to createSelector
      return sortedData;
    } catch (error) {
      return axios.isAxiosError(error) && error.response
        ? rejectWithValue(error.response.data)
        : rejectWithValue(error.message);
    }
  },
);

export const postUploadConfig = createAsyncThunk(
  "media/postUploadConfig",
  async (params: SendUploadConfigParams, { rejectWithValue }) => {
    try {
      const urlParams = handleParams(params);
      const response = await axios({
        method: "post",
        url: `${API_URL}/config/upload-config${urlParams}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      return axios.isAxiosError(error) && error.response
        ? rejectWithValue(error.response.data)
        : rejectWithValue(error.message);
    }
  },
);

export const postUltimateUpload = createAsyncThunk(
  "media/postUltimateUpload",
  async (params: SendUltimateUploadParams, { rejectWithValue }) => {
    try {
      const urlParams = handleParams(params);
      const response = await axios({
        method: "post",
        url: `${API_URL}/media-plan/ultimate-upload${urlParams}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      return axios.isAxiosError(error) && error.response
        ? rejectWithValue(error.response.data)
        : rejectWithValue(error.message);
    }
  },
);

const mediaPlanSlice = createSlice({
  name: "mediaPlan",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMediaPlans.pending, (state) => {
        state.mediaUploadsLoading = true;
        state.mediaUploadsError = null;
      })
      .addCase(
        fetchMediaPlans.fulfilled,
        (state, action: PayloadAction<MediaUpload[]>) => {
          state.mediaUploadsLoading = false;
          state.mediaUploadsData = action?.payload;
        },
      )
      .addCase(fetchMediaPlans.rejected, (state, action) => {
        state.mediaUploadsLoading = false;
        if (typeof action.payload === "string")
          state.mediaUploadsError = action.payload;
      });

    builder
      .addCase(fetchMediaPlan.pending, (state) => {
        state.mediaPlanUploadLoading = true;
        state.mediaPlanUploadError = null;
      })
      .addCase(
        fetchMediaPlan.fulfilled,
        (state, action: PayloadAction<MediaPlanResponse>) => {
          state.mediaPlanUploadLoading = false;
          state.mediaPlanUploadData = action.payload.media_plan;
          state.mediaPlanFilters = action.payload.filters;
          state.mediaPlanSum = action.payload.sum;
        },
      )
      .addCase(fetchMediaPlan.rejected, (state, action) => {
        state.mediaPlanUploadLoading = false;
        if (typeof action.payload === "string")
          state.mediaPlanUploadError = action.payload;
      });

    builder
      .addCase(postUploadConfig.pending, (state) => {
        state.uploadConfigLoading = true;
        state.uploadConfigError = null;
      })
      .addCase(
        postUploadConfig.fulfilled,
        (state, action: PayloadAction<MediaUpload[]>) => {
          state.uploadConfigLoading = false;
          state.uploadConfigData = action.payload;
        },
      )
      .addCase(postUploadConfig.rejected, (state, action) => {
        state.uploadConfigLoading = false;
        if (typeof action.payload === "string")
          state.uploadConfigError = action.payload;
      });

    builder
      .addCase(fetchPlanUploads.pending, (state) => {
        state.planUploadsLoading = true;
        state.planUploadsError = null;
      })
      .addCase(
        fetchPlanUploads.fulfilled,
        (state, action: PayloadAction<PlanUpload[]>) => {
          state.planUploadsLoading = false;
          state.planUploadsData = action.payload;
        },
      )
      .addCase(fetchPlanUploads.rejected, (state, action) => {
        state.planUploadsLoading = false;
        if (typeof action.payload === "string")
          state.planUploadsError = action.payload;
      });

    builder
      .addCase(fetchPlanUpload.pending, (state) => {
        state.planUploadLoading = true;
        state.planUploadError = null;
      })
      .addCase(
        fetchPlanUpload.fulfilled,
        (state, action: PayloadAction<DetailedPlanUpload>) => {
          state.planUploadLoading = false;
          state.planUploadData = action.payload;
        },
      )
      .addCase(fetchPlanUpload.rejected, (state, action) => {
        state.planUploadLoading = false;
        if (typeof action.payload === "string")
          state.planUploadError = action.payload;
      });

    builder
      .addCase(postUltimateUpload.pending, (state) => {
        state.ultimateUploadLoading = true;
        state.ultimateUploadError = null;
      })
      .addCase(postUltimateUpload.fulfilled, (state, action) => {
        state.ultimateUploadLoading = false;
        state.ultimateUploadData = action.payload;
      })
      .addCase(postUltimateUpload.rejected, (state, action) => {
        state.ultimateUploadLoading = false;
        if (typeof action.payload === "string")
          state.ultimateUploadError = action.payload;
      });
  },
});

export const mediaPlanReducer = mediaPlanSlice.reducer;
