import React from "react";

interface Props extends React.HTMLAttributes<HTMLAnchorElement> {}

export const HiddenLink = React.forwardRef<HTMLAnchorElement, Props>(
  ({ className, ...props }, ref) => {
    return (
      <a
        href="/"
        ref={ref}
        className={className}
        download
        style={{ display: "none" }}
        {...props}
      >
        hidden link
      </a>
    );
  }
);
