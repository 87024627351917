import { combineReducers } from "@reduxjs/toolkit";
import { mediaPlanReducer } from "./media";
import { wsDataReducer } from "./ws-data";
import { settingsReducer } from "./settings";
import { globalParamsReducer } from "./globalParams";
import { issuedReducer } from "./issued";

const rootReducer = combineReducers({
  mediaplan: mediaPlanReducer,
  wsdata: wsDataReducer,
  issued: issuedReducer,
  settings: settingsReducer,
  globalParams: globalParamsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
