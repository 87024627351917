import axios, { AxiosResponse } from "axios";
import { API_URL, initState } from "common/const";
import { groupByListId } from "common/utils/groupByListId";
import qs from "qs";

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  FetchClientSourcesParams,
  MessageResponse,
  SendSettingsParams,
  SettingSource,
  SourceData,
  StateType,
} from "./types";

export const postSettingsData = createAsyncThunk(
  "settings/sendSettings",
  async ({ settings }: SendSettingsParams, { rejectWithValue }) => {
    const queryString = qs.stringify(settings, { arrayFormat: "brackets" });

    try {
      const response = await axios({
        method: "post",
        url: `${API_URL}/sources/upload-source?${queryString}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const updateSettingsData = createAsyncThunk(
  "settings/sendUpdatedSettings",
  async ({ settings }: SendSettingsParams, { rejectWithValue }) => {
    const queryString = qs.stringify(settings, { arrayFormat: "brackets" });

    try {
      const response: AxiosResponse<MessageResponse> = await axios({
        method: "patch",
        url: `${API_URL}/sources/update-source?${queryString}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

//TODO: потом протипизируем ответ
export const fetchClientSources = createAsyncThunk<
  any,
  FetchClientSourcesParams
>("sources/fetchClientSources", async ({ client_id }, { rejectWithValue }) => {
  try {
    const response: AxiosResponse<SettingSource[] | MessageResponse> =
      await axios.get(`${API_URL}/sources/get-sources/${client_id}`);
    if (Array.isArray(response.data)) {
      const grouped = groupByListId<SettingSource>(response.data, "id");
      return grouped;
    }
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      return rejectWithValue(error.response.data);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

const initialState: StateType = {
  source: initState,
  sourceUpdate: initState,
};

const sourcesSlice = createSlice({
  name: "sources",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchClientSources.pending, (state) => {
        state.source.loading = true;
        state.source.error = null;
      })
      .addCase(
        fetchClientSources.fulfilled,
        (state, action: PayloadAction<SourceData | MessageResponse>) => {
          state.source.loading = false;
          state.source.data = action.payload;
        },
      )
      .addCase(fetchClientSources.rejected, (state, action) => {
        state.source.loading = false;
        if (typeof action.payload === "string")
          state.source.error = action.payload;
      });
  },
});

export const settingsReducer = sourcesSlice.reducer;
export default {
  settingsReducer,
};
