import React from "react";
import { Link } from "react-router-dom";

import classNames from "classnames";

import styles from "./styles.module.sass";

export interface PaginationProps extends React.HTMLAttributes<HTMLDivElement> {
  text: string;
  success?: boolean;
  link?: string | null;
}

export const Message = React.forwardRef<HTMLDivElement, PaginationProps>(
  ({ text, link, success = false, className, ...props }, ref) => {
    const combinedClassName = classNames(
      styles.message,
      success ? styles.success : styles.error,
      className,
    );

    const addLink = (text: string, link: string): JSX.Element => {
      return (
        <p>
          {text.replace("ссылке", "")}
          <Link to={link} download className={styles.link}>
            ссылке
          </Link>
        </p>
      );
    };

    return (
      <div ref={ref} className={combinedClassName} {...props}>
        {link ? addLink(text, link) : text}
      </div>
    );
  },
);
