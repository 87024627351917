import { useEffect, useRef } from "react";

type DebouncedFunction<T extends any[]> = (...args: T) => void;

export default function useDebouncedFunction<T extends any[]>(
  fn: DebouncedFunction<T>,
  delay: number,
): DebouncedFunction<T> {
  const timerRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  return (...args: T) => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => fn(...args), delay);
  };
}
