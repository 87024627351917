import styled from '@emotion/styled';
import React from 'react';

import {TTextProps} from './types';

export default styled(({className, ...props}: TTextProps) => {
    return <div {...props} className={`HighlightsText ${className}`} />;
})`
    font-size: 14px;
    color: ${({color, theme}) => color ?? theme.colors.text.superDark};
    font-weight: 500;
    text-align: ${({align}) => align ?? 'left'};
    display: ${({display}) => display ?? 'block'};
`;
