
export function getPages(
  entries: number,
  entriesPerPage: number,
  currentPage: number
): Array<string> {
  const lastPageNumber: number = Math.ceil(entries / entriesPerPage);
  const pages: Array<string> = [];
  const visiblePagesNumber = 4;
  const firstVisiblePage: number = currentPage - visiblePagesNumber;
  const lastVisiblePage: number = currentPage + visiblePagesNumber;

  for (
    let currentPageNumber = 1;
    currentPageNumber <= lastPageNumber;
    currentPageNumber++
  ) {
    if (currentPageNumber === 1)
      pages.push(`${new Intl.NumberFormat("ru").format(currentPageNumber)}`);
    else if (
      currentPageNumber >= currentPage &&
      currentPageNumber <= lastVisiblePage
    )
      pages.push(`${new Intl.NumberFormat("ru").format(currentPageNumber)}`);
    else if (
      currentPageNumber <= currentPage &&
      currentPageNumber >= firstVisiblePage
    )
      pages.push(`${new Intl.NumberFormat("ru").format(currentPageNumber)}`);
    else if (currentPageNumber === lastPageNumber)
      pages.push(`${new Intl.NumberFormat("ru").format(currentPageNumber)}`);
    else if (pages[pages.length - 1] !== "...") pages.push("...");
  }

  return pages;
}
