import dayjs from "dayjs";

export function adaptDate(newValue: Date | null): Date | null {
  if (newValue) {
    const timeZoneOffset: number = new Date(newValue).getTimezoneOffset();
    const date = new Date(newValue);
    date.setMinutes(date.getMinutes() - timeZoneOffset);
    return dayjs(date) as unknown as Date;
  }
  return null;
}
