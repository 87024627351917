import {
  ISSUED_ROUTE,
  MEDIA_PLANS_ROUTE,
  WORK_SITE_DATA_ROUTE,
} from "common/const";
import ToDoItem from "./to-do-item";

const ToDoList: React.FC = (): JSX.Element => (
  <>
    <ToDoItem
      title="Загрузить выгрузки avito"
      page={{ name: "Данные работных сайтов", to: WORK_SITE_DATA_ROUTE }}
    />
    <ToDoItem
      title="Загрузить выгрузки hh"
      page={{ name: "Данные работных сайтов", to: WORK_SITE_DATA_ROUTE }}
    />
    <ToDoItem
      title="Загрузить оформленных"
      page={{ name: "Оформленные", to: ISSUED_ROUTE }}
    />
    <ToDoItem
      title="Загрузить план подбора"
      page={{ name: "План подбора", to: MEDIA_PLANS_ROUTE }}
    />
  </>
);

export default ToDoList;
