import {
  MessageResponse,
  SourceData,
} from "services/store/reducers/settings/types";
import { SOURCE_TYPE, SourceType } from "services/store/reducers/ws-data/types";

export function isMessageResponse(obj: any): obj is MessageResponse {
  return (
    typeof obj === "object" &&
    obj !== null &&
    "response" in obj &&
    typeof obj.response === "object" &&
    (("message" in obj.response && typeof obj.response.message === "string") ||
      ("error" in obj.response && typeof obj.response.error === "string"))
  );
}

export function isSourceData(obj: any): obj is SourceData {
  if (!obj || typeof obj !== "object") return false;
  for (const key in obj) {
    if (typeof obj[key] !== "object") return false;
  }
  return true;
}

export const isSourceType = (obj: any): obj is SourceType =>
  SOURCE_TYPE.includes(obj as SourceType);
