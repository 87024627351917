import React, { useEffect, useState } from "react";

import classNames from "classnames";
import { FilterConfiguration } from "common/const";
import { handleDate } from "common/utils/handleDate";
import { isPendingDate } from "common/utils/isPendingDate";
import BasicDatePicker from "components/UI/DatePicker";
import { adaptDate } from "components/UI/DatePicker/helpers";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  value: number | string;
  onFilterChange: (newValue: string, filter: string) => void;
  id: string;
  configuration: FilterConfiguration;
  label: string;
  isErrored?: boolean;
}

export const DatePicker = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      className,
      value,
      onFilterChange,
      id,
      configuration,
      label,
      isErrored = false,
      ...props
    },
    ref,
  ) => {
    const [date, setDate] = useState<Date | null>(null);

    const combinedClassName = classNames(className);

    useEffect(() => {
      if (value) setDate(adaptDate(new Date(value)));
    }, [value]);

    return (
      <div ref={ref} className={combinedClassName} {...props}>
        <BasicDatePicker
          label={label}
          value={date}
          onChange={(newValue) => {
            // не обрабатывать дату, если она не заполнена до конца
            if (isPendingDate(newValue)) return;
            onFilterChange(
              newValue
                ? handleDate(new Date(newValue))
                : configuration.defaults[id]
                  ? configuration.defaults[id].toString()
                  : "",
              id,
            );
          }}
        />
      </div>
    );
  },
);
