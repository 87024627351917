import { HTMLAttributes } from "react";
import styles from "./styles.module.sass";

interface LastUploadDateProps extends HTMLAttributes<HTMLParagraphElement> {
  date: string;
}

const LastUploadDate: React.FC<LastUploadDateProps> = ({
  date,
  ...props
}): JSX.Element => {
  return (
    <p {...props} className={styles.paragraph}>
      <span className={styles.phrase}>Дата последней загрузки:</span>{" "}
      <span className={styles.date}>{date}</span>
    </p>
  );
};

export default LastUploadDate;
