import { forwardRef } from "react";
import { Link } from "react-router-dom";

import Breadcrumbs from "@mui/material/Breadcrumbs";

import classNames from "classnames";
import styles from "./styles.module.sass";
import { Separator } from "./separator";

interface BreadCrumb {
  text: string;
  link: string;
}

export interface BreadCrumbsProps extends React.HTMLAttributes<HTMLDivElement> {
  current: string;
  breadCrumbs: BreadCrumb[];
}

export const BreadCrumbs = forwardRef<HTMLDivElement, BreadCrumbsProps>(
  ({ current, breadCrumbs, className, ...props }, ref) => {
    const combinedClassName = classNames(styles.breadCrumbs, className);

    return (
      <div
        ref={ref}
        className={combinedClassName}
        role="presentation"
        {...props}
      >
        <Breadcrumbs aria-label="breadcrumb" separator={<Separator />}>
          {breadCrumbs.map((breadCrumb) => (
            <Link
              key={breadCrumb.link}
              className={`${styles.breadCrumb} ${styles.enabled}`}
              to={breadCrumb.link}
            >
              {breadCrumb.text}
            </Link>
          ))}
          <span className={`${styles.breadCrumb} ${styles.disabled}`}>
            {current}
          </span>
        </Breadcrumbs>
      </div>
    );
  }
);
