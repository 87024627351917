import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router";

import DownloadIcon from "@mui/icons-material/Download";

import {
  EMPTY_DATA,
  MEDIA_PLAN_CAPTIONS,
  MEDIA_PLAN_COLUMNS,
  PLAN_UPLOAD_COLUMNS,
  RECRUITMENT_FILTERS,
} from "common/const";
import { addOptionalParams } from "common/utils/addOptionalParams";
import { handleDate } from "common/utils/handleDate";
import { Filters, FilterValues } from "components/Filters";
import MediaPlanUploadTable from "components/tables/MediaPlanUploadTable";
import { H1, H2 } from "components/typography";
import { BreadCrumbs } from "components/UI/BreadCrumbs";
import { HiddenLink } from "components/UI/HiddenLink";
import { Loader } from "components/UI/Loader";
import { MUIIconButton } from "components/UI/MUIIconButton";
import { fetchMediaPlan, fetchPlanUpload } from "services/store/reducers/media";
import {
  FetchPlanUploadParams,
  MediaPlanUpload,
  PlanLine,
} from "services/store/reducers/media/types";
import { useAppDispatch, useAppSelector } from "services/store/store";

import commonStyles from "../common-styles.module.sass";
import { fetchAndDownloadFile, getPrevBreadCrumb } from "./helpers";

const MediaPlanPage: React.FC = (): JSX.Element => {
  const [data, setData] = useState<MediaPlanUpload[] | PlanLine[]>([]);
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const downloadRef = useRef<HTMLAnchorElement>(null);

  const { id, history, uploader_name } = useMemo(() => {
    const urlParams = new URLSearchParams(search);
    return {
      id: Number(urlParams.get("id")),
      history: urlParams.get("history") ?? "mediaplans",
      uploader_name: urlParams.get("uploader_name") ?? "",
    };
  }, [search]);

  const prevBreadCrumb = useMemo(() => getPrevBreadCrumb(history), [history]);

  const getPlaceName = (): string =>
    isMediaPlan() ? "Медиаплан" : "План подбора";

  const {
    mediaPlanUploadData,
    mediaPlanUploadLoading,
    planUploadData,
    planUploadLoading,
  } = useAppSelector((state) => state.mediaplan);

  const isMediaPlan = useCallback(() => {
    return search.includes("media");
  }, [search]);

  const loadData = useCallback(() => {
    if (isMediaPlan())
      dispatch(fetchMediaPlan({ upload: id, filter_uploader: uploader_name }));
    else dispatch(fetchPlanUpload({ plan_id: id }));
  }, [dispatch, id, isMediaPlan, uploader_name]);

  const showDetails = (): JSX.Element => {
    const date: string | undefined = planUploadData?.uploaded_at;
    return (
      <section className={commonStyles.details}>
        <H2 color="#0C3953">{planUploadData?.uploader_name}</H2>
        <H2 color="#0C3953" className={commonStyles.detail}>
          {handleDate(date ?? "", true, true)}
        </H2>
      </section>
    );
  };

  const handleGetExcel = (): void => {
    fetchAndDownloadFile(
      `/media-plan/get-clients-media-plan/${id}?filter_uploader=${uploader_name}`,
      downloadRef,
    );
  };

  const handleUpdateFilters = (filterValues: FilterValues): void => {
    const sendParams: FetchPlanUploadParams = {
      plan_id: id,
    };
    addOptionalParams(sendParams, filterValues);
    dispatch(fetchPlanUpload(sendParams));
  };

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    if (isMediaPlan() && mediaPlanUploadData) setData(mediaPlanUploadData);
    else if (planUploadData) setData(planUploadData.plan_lines);
  }, [mediaPlanUploadData, planUploadData, isMediaPlan]);

  return (
    <>
      <BreadCrumbs
        breadCrumbs={
          prevBreadCrumb
            ? [{ text: prevBreadCrumb.text, link: `/${prevBreadCrumb.link}` }]
            : []
        }
        current={getPlaceName()}
      />
      <section className={commonStyles.heading}>
        <H1 color="#0C3953">
          {getPlaceName()} №{id}{" "}
          {isMediaPlan() && (
            <>
              <HiddenLink ref={downloadRef} />
              <MUIIconButton
                icon={
                  <DownloadIcon
                    className={commonStyles.download}
                    color="success"
                  />
                }
                onClick={handleGetExcel}
              />
            </>
          )}
        </H1>
        {!isMediaPlan() && (
          <Filters
            onUpdateFilters={handleUpdateFilters}
            configuration={RECRUITMENT_FILTERS}
          />
        )}
      </section>
      {!isMediaPlan() && showDetails()}
      <section>
        {planUploadLoading || mediaPlanUploadLoading ? (
          <Loader />
        ) : data ? (
          <MediaPlanUploadTable
            data={data}
            columns={isMediaPlan() ? MEDIA_PLAN_COLUMNS : PLAN_UPLOAD_COLUMNS}
            captions={isMediaPlan() ? MEDIA_PLAN_CAPTIONS : undefined}
          />
        ) : (
          EMPTY_DATA
        )}
      </section>
    </>
  );
};

export default MediaPlanPage;
