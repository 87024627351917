import { useState } from "react";
import { SettingSource } from "services/store/reducers/settings/types";
import { hasError, isValidSourceId } from "./utils";
import { RatesErrors } from "./types";

type ValidateAndUpdateFunction = (
  id: number,
  field: keyof SettingSource,
  newValue: string
) => void;

export function useValidateSourceId(
  initialErrors: RatesErrors
): [RatesErrors, ValidateAndUpdateFunction] {
  const [validateErrors, setValidateErrors] = useState(initialErrors);

  function validateAndUpdate(
    id: number,
    field: keyof SettingSource,
    newValue: string
  ): void {
    if (field === "source_id") {
      if (isValidSourceId(newValue) && hasError(validateErrors, id, field)) {
        setValidateErrors((prevErrors) => ({
          ...prevErrors,
          [id]: {
            ...prevErrors[id],
            [field]: "",
          },
        }));
      } else if (!isValidSourceId(newValue)) {
        setValidateErrors((prevErrors) => ({
          ...prevErrors,
          [id]: {
            ...prevErrors[id],
            [field]: "от 0 до 100",
          },
        }));
      }
    }
  }

  return [validateErrors, validateAndUpdate];
}
