import { toast, ToastContainer } from "react-toastify";

import { ERROR_FILE_UPLOAD, SUCCESS_FILE_UPLOAD } from "common/const";

import "react-toastify/dist/ReactToastify.css";

import { useCallback } from "react";

export default function useNotify() {
  const notifyUploading = useCallback(
    (name: string, type: "success" | "error") =>
      toast[type](
        `${name} - ${type === "success" ? SUCCESS_FILE_UPLOAD : ERROR_FILE_UPLOAD}`,
        {
          position: "bottom-right",
          autoClose: 5000,
        },
      ),
    [],
  );

  return { notifyUploading, ToastContainer };
}
