import axios, { AxiosResponse } from "axios";
import { API_URL, initState } from "common/const";
import { handleParams } from "common/utils/handleParams";

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { CandidatesData, FetchCandidatesDataParams, StateType } from "./types";

const initialState: StateType = {
  candidatesData: initState,
};

export const fetchCandidatesData = createAsyncThunk(
  "issued/fetchCandidatesData",
  async (params: FetchCandidatesDataParams, { rejectWithValue }) => {
    try {
      const urlParams = handleParams(params, ["client_id"]);
      const response: AxiosResponse<CandidatesData[]> = await axios.get(
        `${API_URL}/candidates/get-candidates/${params.client_id}/${urlParams}`,
      );
      const data: CandidatesData[] = response.data;
      return data;
    } catch (error) {
      return axios.isAxiosError(error) && error.response
        ? rejectWithValue(error.response.data)
        : rejectWithValue(error.message);
    }
  },
);

const issuedSlice = createSlice({
  name: "issued",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCandidatesData.pending, (state) => {
        state.candidatesData.loading = true;
        state.candidatesData.error = null;
      })
      .addCase(
        fetchCandidatesData.fulfilled,
        (state, action: PayloadAction<CandidatesData[]>) => {
          state.candidatesData.loading = false;
          state.candidatesData.data = action.payload;
        },
      )
      .addCase(fetchCandidatesData.rejected, (state, action) => {
        state.candidatesData.loading = false;
        if (typeof action.payload === "string")
          state.candidatesData.error = action.payload;
      });
  },
});

export const issuedReducer = issuedSlice.reducer;
